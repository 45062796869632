import * as React from 'react';
import { Bx, ClickableCard, IBxProps, ICarouselResponsiveProps, ICtnProps, LayoutImage, Typo, useBreakpoints } from '@curry-group/mui-curcuma';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { shuffle as _shuffle } from 'lodash';

import { fetchLandingPageElementsRequest } from '../../data/reducer/data';
import { getDetailRoute, TYPES_PROPS } from '../../helper';
import { LandingPageCardSection } from './card-section';
import { LandingPageKeyVisual } from './keyvisual';
import { LandingPageQuoteSection } from './quote-section';
import { LandingPageSectionWrapper } from './section-wrapper';
import { LandingPageTabSection } from './tab-section';

import { LandingpageFooterImpl } from './impl/footer';
import { LandingpageHeaderImpl } from './impl/header';
import { useState } from 'react';
import { useFilterConfig } from '../../data/sagas/filter';

interface IQuoteItem {
  imgSrc?: string;
  imgAlt?: string;
  content?: string;
  name?: string;
  function?: string;
}

const quoteItems: IQuoteItem[] = [
  {
    imgSrc: '/images/landingpage/prof-dr-dr-markus-ferrari.jpg',
    imgAlt: 'Bild von Prof. Dr. Dr. Markus Ferrari',
    content:
      'Weil die Plattform für Mediziner ein ideales Umfeld bietet, um den Versorgungsbedarf aus dem klinischen Alltag und innovative Lösungsideen aus der Medizintechnik zusammenzubringen.',
    name: 'Prof. Dr. Dr. Markus Ferrari',
    function: 'Direktor der Klinik Innere Medizin I: Kardiologie, Angiologie & konservative Intensivmedizin, Helios Dr. Horst Schmidt Kliniken Wiesbaden'
  },
  {
    imgSrc: '/images/landingpage/franziska-glas.jpg',
    imgAlt: 'Bild von Franziska Glas',
    content:
      'Weil wir als junges innovatives Unternehmen daran interessiert sind, unser Netzwerk stetig zu erweitern, um Wissen und Kontakte in der Orthopädietechnik interdisziplinär auszutauschen und konstruktiv zu kooperieren.',
    name: 'Franziska Glas',
    function: 'Projektleiterin & Simulation bei Mecuris GmbH'
  },
  {
    imgSrc: '/images/landingpage/dr-ing-axel-boese.jpg',
    imgAlt: 'Bild von Dr.-Ing. Axel Boese',
    content:
      'Um den Forschungstransfer in die klinische Anwendung global wie regional zu beschleunigen, bedarf es neuer Konzepte. Medtec Online bietet eine geeignete Plattform, um unsere FuE-Projekte weiterzuentwickeln und neue Transferstrategien auszuloten.',
    name: 'Dr.-Ing. Axel Boese',
    function: 'Projekt Manager INKA Application Driven Research, Medizinische Fakultät, Otto-von-Guericke-Universität Magdeburg'
  },
  {
    imgSrc: '/images/landingpage/dr-erwin-keeve.jpg',
    imgAlt: 'Bild von Prof. Dr.-Ing. Erwin Keeve',
    content:
      'Medtec Online wird zu der Plattform für Medizintechnik in Deutschland und Europa, ermöglicht es doch den Akteuren eine unmittelbare, direkte Vernetzung sowie Bewertung der Beiträge und Vorschläge zur Verbesserung der Gesundheitswirtschaft und damit unserer Gesellschaft.',
    name: 'Prof. Dr.-Ing. Erwin Keeve',
    function: 'Fachgebiet Navigation & Robotik, Charité Universitätsmedizin Berlin'
  },
  {
    imgSrc: '/images/landingpage/claudia_moeller.jpg',
    imgAlt: 'Bild von Claudia Möller',
    content:
      'Weil ein aktives Innovationsmanagement im Krankenhaus wichtig ist und diese Plattform die Chance zum direkten Austausch mit Innovatoren in einem gesicherten Rahmen bietet.',
    name: 'Claudia Möller',
    function: 'Leiterin Zentraler Dienst FuE & Innovationsmanagement, Agaplesion gAG'
  },
  {
    imgSrc: '/images/landingpage/laura-wamprecht.jpg',
    imgAlt: 'Bild von Laura Wamprecht',
    content:
      'Um innovative Produkte, Services und Geschäftsmodelle zu entwickeln, sind der Wissensaustausch und die Zusammenarbeit in vertrauenswürdigen Netzwerken essenziell. Deshalb bin ich auf Medtec Online aktiv dabei.',
    name: 'Laura Wamprecht',
    function: 'Managing Director, Flying Health GmbH'
  },
  {
    imgSrc: '/images/landingpage/christian-fred-hieronimi.jpg',
    imgAlt: 'Bild von Christian Fred Hieronimi',
    content:
      'In der Zukunft geht es darum, Patienten, Mediziner und Hersteller direkt und permanent miteinander zu vernetzen. Realtime, ohne Latenzen. Zu 100% transparent und sicher. Unter Wahrung behördlicher Vorgaben und des Datenschutzes. Medtec Online hilft den Akteuren bei Koordination und Austausch und treibt damit Innovationen in der Gesundheitsversorgung voran.',
    name: 'Christian Fred Hieronimi',
    function: 'CEO Oncare GmbH'
  },
  {
    imgSrc: '/images/landingpage/dr-med-philipp-feldle.jpg',
    imgAlt: 'Bild von Dr. Philipp Feldle',
    content:
      'Ich bin hier, um mich mit Experten aus ganz unterschiedlichen Wissensgebieten, aber gleichem Mindset und gleicher Begeisterung für neue Technologien unkompliziert und direkt austauschen zu können.',
    name: 'Dr. Philipp Feldle',
    function: 'Oberarzt Radiologie, Universitätsklinikum Würzburg'
  },
  {
    imgSrc: '/images/landingpage/dominik-zenth.jpg',
    imgAlt: 'Bild von Dominik Zenth',
    content:
      'Als UX Consultant bin ich in Projekten häufig das Bindeglied zwischen Entwicklung, Management und End-Anwendern. Sich mit allen auf einer Plattform austauschen zu können, finde ich super.',
    name: 'Dominik Zenth',
    function: 'User Experience Consultant bei UID in München'
  },
  {
    imgSrc: '/images/landingpage/prof-dr-ken-herrmann.jpg',
    imgAlt: 'Bild von Prof. Dr. Ken Herrmann',
    content:
      'Der Fortschritt in bildgebender Diagnose und Therapie erfordert eine enge Zusammenarbeit von Klinik, Wissenschaft und Industrie. Medtec Online ermöglicht eine strukturierte Diskussion zu unseren klinischen Bedarfen und individuelle Ansätze durch die Vielzahl der vorhandenen Partner. Projekte können gezielt angestoßen und Technologien in die Tat umgesetzt werden.',
    name: 'Prof. Dr. Ken Herrmann',
    function: 'Ärztlicher Direktor der Klinik für Nuklearmedizin, Universitätsklinik Essen'
  }
];

const QuoteItemComponent: React.FC<{ item: IQuoteItem; imageLoaded?: Function }> = ({ item, imageLoaded }) => {
  const theme = useTheme();
  const responsive = useBreakpoints();
  const isMDUp = responsive.mdUp || false;
  const quoteIconSize = isMDUp ? 48 : 24;
  const body4FontSize = isMDUp ? 20 : 16;
  return (
    <Bx style={{ borderRadius: theme.shape.borderRadius4, overflow: 'hidden' }}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <LayoutImage src={item.imgSrc} alt={item.imgAlt} width="100%" height="100%" objectFit="cover" objectPosition="center" onLoadCallback={imageLoaded} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Bx
            bgcolor="primary.main"
            color="primary.contrastText"
            height="100%"
            py={{ xs: 3, md: 6 }}
            px={{ xs: 2, md: 4 }}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Bx>
              <Bx color="accent.main" style={{ fontSize: quoteIconSize }}>
                <FontAwesomeIcon icon={faQuoteRight} />
              </Bx>
              <Bx pl={{ xs: 3, md: 7 }} pr={{ xs: 2, md: 6 }}>
                <Typo fontWeight={500} variant="body1" component="blockquote" style={{ fontSize: body4FontSize }}>
                  {item.content}
                </Typo>
              </Bx>
              <Bx color="accent.main" style={{ fontSize: quoteIconSize }} textAlign="right">
                <FontAwesomeIcon icon={faQuoteLeft} />
              </Bx>
            </Bx>
            <Bx mt={{ xs: 2, md: 5 }} pl={{ xs: 3, md: 7 }} pr={{ xs: 2, md: 6 }}>
              <Typo variant="body2" fontWeight={500}>
                {item.name}
              </Typo>
              <Typo variant="body2">{item.function}</Typo>
            </Bx>
          </Bx>
        </Grid>
      </Grid>
    </Bx>
  );
};

export const LandingPageFrame = () => {
  const appconfig = useSelector(state => state.foundation.appconfig);
  useFilterConfig('landingpage', false, false);
  const data = useSelector(state => state.data?.['landingpage']?.items);
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const [scrolled, setScrolled] = React.useState(false);
  const [, setQuoteCarouselLoad] = React.useState<number>(0);
  const [sliderItems] = useState(_shuffle(quoteItems));

  const view = 'default';
  const responsive = useBreakpoints();
  const isXLUp = responsive.xlUp || false;
  const isMDUp = responsive.mdUp || false;
  const containerMaxWidth: ICtnProps['maxWidth'] = 'lg';
  const containerMaxWidthHeader: ICtnProps['maxWidth'] = 'lg';

  const h1FontSize = 48;
  const body4FontSize = isMDUp ? 20 : 16;
  const sectionPy: IBxProps['py'] = { xs: 6, md: 6.25 };
  const sectionPb: IBxProps['mb'] = { xs: 6, md: 6.25 };
  const keyVisualSectionPy: IBxProps['py'] = { xs: 6, md: 20 };
  const sectionPx: IBxProps['px'] = { xs: 4, md: 4 };
  const innerSectionSpacing: GridProps['spacing'] = isMDUp ? 10 : 5;

  // Scroll handling
  React.useEffect(() => {
    function setScrolling() {
      if (scrolled && window.scrollY < 100) {
        setScrolled(false);
      } else if (!scrolled && window.scrollY >= 100) {
        setScrolled(true);
      }
    }
    setScrolling();
    window.addEventListener('scroll', setScrolling);
    return () => {
      window.removeEventListener('scroll', setScrolling);
    };
  });
  React.useEffect(() => {
    dispatch(fetchLandingPageElementsRequest());
  }, [dispatch, location.pathname]);

  const onQuoteImageLoaded = () => {
    setQuoteCarouselLoad(Date.now());
  };
  const responsiveCommunityCarousel: ICarouselResponsiveProps = {
    0: { items: 1 },
    [theme.breakpoints.values.sm]: { items: 2 }
  };

  let mappedCards: React.ReactElement[] = [];
  if (data && data.length) {
    mappedCards = data?.map((item, idx) => {
      const cardItem = { typeId: item.type, alias: item.seo?.alias, _source: { ...item, content: { ...item.content } }, _id: item?._id, _score: 0 };
      return (
        <Bx key={idx} m={{ xs: 0, sm: 0.5, md: 0 }}>
          <ClickableCard
            color={TYPES_PROPS[item.type].CardColor}
            hoverColor={TYPES_PROPS[item.type].CardColor}
            backgroundImage={TYPES_PROPS[item.type].FallbackImage?.src}
            aspect="16:10"
            height="100%"
            to={getDetailRoute(cardItem, appconfig || {}, location) + '?ref=' + encodeURIComponent('/')}
          >
            <Bx py={2} px={2} height="100%" display="flex" flexDirection="column">
              <Bx display="flex" mb="auto">
                <Bx>
                  <Typo gutterBottom display="block" variant={'subtitle2'} component="span" textTransform="uppercase">
                    {TYPES_PROPS[item.type].Name}
                  </Typo>
                </Bx>
              </Bx>
              <Bx mt={2}>
                <Typo variant="h5" component="h3" maxLines={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 4 }}>
                  {item.content.title}
                </Typo>
              </Bx>
            </Bx>
          </ClickableCard>
        </Bx>
      );
    });
  }

  return (
    <Bx style={{ gridArea: 'content' }}>
      <LandingpageHeaderImpl view={view} scrolled={scrolled} backButtonLink={undefined} />
      {view === 'default' && (
        <React.Fragment>
          <LandingPageKeyVisual
            containerMaxWidth={containerMaxWidthHeader}
            sectionPy={keyVisualSectionPy}
            sectionPx={sectionPx}
            sectionPb={sectionPb}
            h1FontSize={h1FontSize}
            body4FontSize={body4FontSize}
            imageSrc="images/landingpage/keyvisual.svg"
            imageAlt="Alt-Text der Illustration"
            registerLabel="Jetzt registrieren"
            registerLink="/optin"
            loginLabel="Anmelden"
            loginLink="/login"
            headline=" Das neue Wir
                  <br />
                  für die Versorgung"
            content="Werden Sie Teil der wachsenden Fach-Community für ein erfolgreiches Zusammenspiel von Medizintechnik, Innovation und Versorgung!"
            responsive={responsive}
          />
          <LandingPageSectionWrapper sectionPy={sectionPy} sectionPx={sectionPx}>
            <LandingPageCardSection
              headline="Aktuelles aus der Community"
              items={mappedCards}
              containerMaxWidth={containerMaxWidth}
              responsive={responsive}
              responsiveSlider={responsiveCommunityCarousel}
            />
          </LandingPageSectionWrapper>
          <LandingPageSectionWrapper sectionPy={sectionPy} sectionPx={sectionPx}>
            <LandingPageTabSection
              containerMaxWidth={containerMaxWidth}
              innerSectionSpacing={innerSectionSpacing}
              responsive={responsive}
              sectionPx={sectionPx}
              imageSrc="images/landingpage/keyvisual.svg"
              videoSrc="videos/teaser_video.mp4"
              imageAlt="Alt-Text der Illustration"
            />
          </LandingPageSectionWrapper>
          <LandingPageSectionWrapper sectionPy={sectionPy} sectionPx={sectionPx}>
            <LandingPageQuoteSection
              headline="Warum Medtec Online?"
              containerMaxWidth={containerMaxWidth}
              items={sliderItems.map(item => (
                <QuoteItemComponent item={item} imageLoaded={onQuoteImageLoaded} />
              ))}
              responsive={{ isMDUp, isXLUp }}
              theme={theme}
            />
          </LandingPageSectionWrapper>
        </React.Fragment>
      )}
      <LandingpageFooterImpl />
    </Bx>
  );
};
