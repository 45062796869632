import React from 'react';
import { FormShareMessage } from '../../form/compositions/FormShareMessage';
import { FramedContent } from '../../framed-content';

const ShareMessageForm = (props: any) => {
  if (!props.flowData?.itemData?.message || props.flowData?.itemData?.message?.length < 100 || !props.flowData?.itemData?.sharedItem) {
    props.setNextStepDisabled(true);
  } else {
    props.setNextStepDisabled(false);
  }
  return (
    <FramedContent>
      <FormShareMessage
        formData={props.flowData}
        headline={'Ok, dann erzählen Sie doch etwas mehr...'}
        subtitle={'Inwiefern ist der geteilte Inhalt für Kooperierende, Ihr Projekt oder die Fachcommunity von Interesse?'}
        onChange={formData => {
          props.flowFunctions.setFlowData({ ...props.flowData, ...formData });
        }}
      />
    </FramedContent>
  );
};

export default ShareMessageForm;
