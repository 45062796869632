import React, { ReactNode, useEffect, useRef } from 'react';
import { Bx, IBxProps, ScrollContainer } from '@curry-group/mui-curcuma';
import { CircularProgress, RootRef } from '@material-ui/core';
import { EmptyContent } from '../empty-content';
import { IMessageModel } from '../../../model/communication/Message';
import * as dayjs from 'dayjs';

import { MessageWrapper } from '../message/wrapper';
import { MessageDivider } from '../message/divider';
import * as _ from 'lodash';
import { CommunicationType } from '../../../model/communication/Communication';
import { ParticipationRequestStatus } from '../../../model/communication/Participant';
import { useSelector } from 'react-redux';

export interface IMessagesProps {
  currentUserId: string;

  allowThreading?: boolean;
  allowQuotes?: boolean;
  inlineThreading?: boolean;
  allowVoting?: boolean;

  messages?: IMessageModel[];

  setScrolledDown?: (atBottom: boolean) => void;
  scrolledDown?: boolean;
  scrollContainer?: boolean;

  working?: boolean;
  olderWorking?: boolean;
  atBottom?: boolean;
  messageWidth?: number;
  voteUpClick?: (message: IMessageModel) => void;
  voteDownClick?: (message: IMessageModel) => void;
  threadingClicked?: (message: IMessageModel) => void;
  quoteClicked?: (message: IMessageModel) => void;
  emoteClicked?: (message: IMessageModel, emoji: string) => void;
  onScrollBottomChange(isBottom: boolean): void;
  loadThreadMessages?: (message: IMessageModel) => void;
  dateDivider?: boolean;
  manageMessages?: boolean;
  editMessage?: (message: IMessageModel) => void;
  deleteMessage?: (message: IMessageModel) => void;
  onMessageRead?: (message: IMessageModel) => void;
  joinConference?: (message: IMessageModel) => void;
  renderEmptyContent?: () => ReactNode;
  atStart?: boolean;
  atEnd?: boolean;
  hint?: string;
  fetchOlderMessages?: (before: number) => void;
  newerWorking?: boolean;
  fetchNewerMessages?: (before: number) => void;
  focusMessage?: string;
  messageThread?: string;
  p?: IBxProps['p'];
  onReferenceSelected?: (type: string, data: any) => void;

  activeParticipants?: any;
  alias?: string;

  shareClicked?: (message: IMessageModel) => void;
  isPublic: boolean;
  isListed: boolean;
}

function getTimestampDividerContent(prev: number, next: number) {
  const parsedPrev = dayjs.unix(prev);
  const parsedNext = dayjs.unix(next);

  const startOfPrev = parsedPrev.startOf('d');
  const startOfNext = parsedNext.startOf('d');

  if (startOfPrev.unix() === startOfNext.unix()) {
    // same day, do nothing
  } else {
    // not the same day
    const parsedNow = dayjs.unix(Date.now() / 1000);
    const startOfNow = parsedNow.startOf('d');
    if (startOfNext.unix() === startOfNow.unix()) {
      // next is first element of today
      return 'Heute';
    } else if (startOfNext.add(1, 'd').unix() === startOfNow.unix()) {
      // next is first element of yesterday
      return 'Gestern';
    } else {
      return parsedNext.format('DD.MM.YYYY');
    }
  }
}

export const Messages: React.FC<IMessagesProps> = ({
  currentUserId,
  scrolledDown,
  setScrolledDown,
  scrollContainer,
  messages,
  allowVoting,
  allowThreading,
  allowQuotes,
  quoteClicked,
  emoteClicked,
  threadingClicked,
  shareClicked,
  messageWidth,
  working,
  inlineThreading,
  olderWorking,
  loadThreadMessages,
  voteUpClick,
  joinConference,
  voteDownClick,
  dateDivider,
  manageMessages,
  editMessage,
  deleteMessage,
  onMessageRead,
  renderEmptyContent,
  atStart,
  atEnd,
  hint,
  newerWorking,
  fetchNewerMessages,
  fetchOlderMessages,
  focusMessage,
  messageThread,
  p,
  onReferenceSelected,
  activeParticipants,
  alias,
  isPublic,
  isListed
}) => {
  function renderMessages(isPublic: boolean, messages?: IMessageModel[]) {
    let lastTimestamp = 0;
    const result: ReactNode[] = [];
    if (messages && messages.length) {
      let lastWasThread = false;
      messages.forEach((message, index) => {
        if (dateDivider) {
          let divider: string | undefined = undefined;
          if (lastTimestamp) {
            divider = getTimestampDividerContent(lastTimestamp / 1000, message.createdAt / 1000);
          }
          lastTimestamp = message.createdAt;
          if (divider) {
            result.push(<MessageDivider key={'divider-' + index} caption={divider} />);
          } else if (lastWasThread && inlineThreading) {
            result.push(<MessageDivider key={'divider-after-thread-' + index} />);
          }
        } else if (index !== 0) {
          result.push(<MessageDivider key={'divider-after-thread-' + index} />);
        }

        lastWasThread = false;

        result.push(
          <MessageWrapper
            currentUserId={currentUserId}
            onMessageRead={onMessageRead}
            allowQuotes={allowQuotes}
            quoteClicked={() => quoteClicked?.(message)}
            emoteClicked={(message: IMessageModel, emoji:string) => emoteClicked?.(message, emoji)}
            allowVoting={allowVoting}
            voteUpClick={() => voteUpClick?.(message)}
            voteDownClick={() => voteDownClick?.(message)}
            inlineThreading={inlineThreading}
            threadingClicked={() => threadingClicked?.(message)}
            allowThreading={allowThreading}
            loadThreadMessages={loadThreadMessages}
            width={messageWidth}
            key={message.id}
            message={message}
            deleteMessage={deleteMessage}
            editMessage={editMessage}
            manageMessages={manageMessages}
            onJoinConference={joinConference}
            onReferenceSelected={onReferenceSelected}
            activeParticipants={activeParticipants}
            shareClicked={() => shareClicked?.(message)}
            isPublic={isPublic}
            isListed={isListed}
          />
        );
        if (message.numThreadChildren) lastWasThread = true;
      });
    }
    return result;
  }
  // const location = useLocation();
  // const history = useHistory();
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const thresold = 800;
  const scrollTop = useRef(0);
  const atTop = useRef(false);
  const atBottom = useRef(true);
  const length = useRef(0);
  const height = useRef(0);
  const initial = useRef(true);
  const inserted = useRef(true);

  const onScroll = _.debounce(e => {
    const elem = scrollContainer ? scrollRef.current : e.target.documentElement;
    if (elem) {
      const scrollTopVal = elem.scrollTop;
      const scrollHeightVal = elem.scrollHeight;
      const clientHeightVal = elem.clientHeight;
      scrollTop.current = scrollTopVal;
      if (atTop.current && scrollTop.current > thresold) {
        atTop.current = false;
      } else if (!atTop.current && scrollTop.current < thresold) {
        atTop.current = true;
        if (scrollTopVal < 20) {
          elem.scrollTop = 20;
        }
        if (!initial.current && inserted.current && messages && !olderWorking && !atEnd) {
          fetchOlderMessages?.(messages[0].createdAt);
          inserted.current = false;
        }
      }

      if (!atBottom.current && scrollHeightVal - scrollTopVal - clientHeightVal < thresold) {
        atBottom.current = true;
      } else if (atBottom.current && scrollHeightVal - scrollTopVal - clientHeightVal > thresold) {
        atBottom.current = false;
      }

      if (scrollTopVal > (0.66 * (scrollHeightVal - clientHeightVal))) {
        if (!initial.current && inserted.current && messages && !newerWorking && !atStart) {
          fetchNewerMessages?.(messages[messages.length - 1].createdAt);
        }
      }

      if (scrolledDown && scrollHeightVal - scrollTopVal - clientHeightVal > 10) {
        setScrolledDown?.(false);
      } else if (!scrolledDown && scrollHeightVal - scrollTopVal - clientHeightVal < 10) {
        setScrolledDown?.(true);
        const bottomMessage = messages && messages.length && messages[messages.length - 1];
        bottomMessage && onMessageRead?.(bottomMessage);
      }
    }
  }, 200);

  useEffect(() => {
    var elem = scrollContainer ? scrollRef.current : window;
    elem?.addEventListener('scroll', onScroll);
    return () => elem?.removeEventListener('scroll', onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScroll]);

  useEffect(() => {
    const elem = scrollContainer ? scrollRef.current : document.querySelector('html');
    if (elem) {
      const scrollHeight = elem?.scrollHeight || 0;
      const sT = elem?.scrollTop || 0;
      if (initial.current) {
        if (messages && messages.length) {
          const focusMessageId = !hint ? focusMessage : undefined;
          if (alias === 'main' || (!!focusMessageId && focusMessageId !== alias)) {
            initial.current = false;
          }
          length.current = messages.length;
          if ((alias === 'main' && !!focusMessageId) || (alias !== 'main' && !!messageThread)) {
            const msgId = alias === 'main' ? focusMessageId : messageThread;
            const msg = !!msgId ? document.getElementById(msgId) : null;
            if (!!msg) {
              if (msg.children?.length === 2) {
                const messagewrapper = msg.children[1] as HTMLElement;
                if (!!messagewrapper && messagewrapper.children?.length === 1) {
                  const msginner = messagewrapper.children[0] as HTMLElement;
                  msginner.style.background = "#80CDEC"; // accent
                  msginner.style.color = "#000"; // text primary
                }
              }
              if (!!messageThread) {
                msg.scrollIntoView({ block: "center" });
              } else {
                msg.scrollIntoView({behavior: "smooth", block: "center"});
              }
              const msgAnswer = document.getElementById('highlighted_message_answer');
              if (!!msgAnswer) {
                msgAnswer.style.background = "#80CDEC"; // accent
                msgAnswer.style.color = "#000"; // text primary
              }
            }
          } else {
            elem.scrollTop = scrollHeight - elem?.clientHeight;
            const bottomMessage = messages && messages.length && messages[messages.length - 1];
            bottomMessage && onMessageRead?.(bottomMessage);
          }
        }
        height.current = scrollHeight;
      } else {
        if (messages && messages.length) {
          const newTop = scrollHeight - height.current + sT;
          if (scrolledDown) {
            elem.scrollTop = scrollHeight - elem?.clientHeight;
            const bottomMessage = messages && messages.length && messages[messages.length - 1];
            bottomMessage && onMessageRead?.(bottomMessage);
          }

          height.current = scrollHeight;
          scrollTop.current = newTop;
          inserted.current = true;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, length, height, focusMessage, onMessageRead, messageThread, alias]);
  
  var content = (
    <>
      {!!(renderEmptyContent && !working && (!messages || !messages.length)) ? (
        <Bx height="100%" display="flex" alignItems="center">
          {renderEmptyContent()}
        </Bx>
      ) : (
        <Bx p={p}>
          {(!atEnd || olderWorking) && !working && (
            <Bx display="flex" justifyContent="center" mb={5}>
              <CircularProgress color="primary" />
            </Bx>
          )}
          {working && (
            <Bx display="flex" justifyContent="center" mb={5}>
              <CircularProgress color="primary" />
            </Bx>
          )}

          {!!(!working && messages && messages.length) && renderMessages(isPublic, messages)}

          {(!atStart || newerWorking) && !working && (
            <Bx display="flex" justifyContent="center" mb={5}>
              <CircularProgress color="primary" />
            </Bx>
          )}
        </Bx>
      )}
    </>
  );

  if (scrollContainer)
    return (
      <Bx flexGrow={1} style={{ overflowY: 'hidden' }}>
        <RootRef rootRef={scrollRef}>
          <ScrollContainer>{content}</ScrollContainer>
        </RootRef>
      </Bx>
    );

  return content;
};

export const ConnectedEmptyContent = () => {
  const pendingSingleOwn = useSelector(
    state => state.communication?.communication?.type === CommunicationType.ONETOONE && state.communication.participation?.status === ParticipationRequestStatus.PENDING
  );

  const pendingSingleOther = useSelector(state => {
    if (state.communication?.communication?.type !== CommunicationType.ONETOONE) return false;
    // find other participation
    var userId = state.foundation.profile?.userId;
    if (!userId) return false;
    var otherParticipation = state.communication.communication.participants.find(p => p.user !== userId);
    if (!otherParticipation) return false;
    return otherParticipation.status === ParticipationRequestStatus.PENDING;
  });

  let caption = '';
  if (pendingSingleOther) caption = 'Ihre Einladung zum Chat wurde noch nicht akzeptiert';
  if (pendingSingleOwn) caption = 'Bitte antworten Sie auf Ihre Einladung';

  return <EmptyContent caption={caption} />;
};
