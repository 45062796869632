import React, { useEffect, useState } from 'react';
import { Typo, Bx, IconBtn } from '@curry-group/mui-curcuma';
import { faMessageBot, faRedo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';
import remarkGfm from 'remark-gfm';
import { useDispatch, useSelector } from 'react-redux';
import useTheme from '@material-ui/core/styles/useTheme';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { debounce as _debounce } from 'lodash';
import { useFilterState } from '../../../data/sagas/filter';
import Markdown from 'react-markdown';
import { Skeleton } from '@material-ui/lab';
import { changeQueryTermAction } from '../../../data/actions/filter';
import { botResponseEndAction, trackBotFeedbackAction, fetchBotAnswerRequestAction, botSetFingerprintAction } from '../../../data/actions/bot';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

interface IBotSearchProps {
  headline?: string;
}

const useStyles = makeStyles(theme => ({
  markdown: {
    '& > *:first-child': {
      marginTop: 0
    },
    '& pre': {
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word'
    }
  }
}));

export const BotSeearch: React.FunctionComponent<IBotSearchProps> = ({
    headline
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const botSearchActive = useSelector(state => state.bot?.botSearchActive);
  const botWorking = useSelector(state => state.bot?.botSearch?.botWorking);
  const botAnswer = useSelector(state => state.bot?.botSearch?.botAnswer);
  const botRequestId = useSelector(state => state.bot?.botSearch?.botRequestId);
  const botFeedback = useSelector(state => state.bot?.botSearch?.botFeedback);
  const positiveFeedback = useSelector(state => state.filter?.filterItem?.content?.positiveFeedback);
  const negativeFeedback = useSelector(state => state.filter?.filterItem?.content?.negativeFeedback);
  const { searchObject } = useFilterState();

  const fingerprint = useSelector(state => state.bot?.fingerprint);

  useEffect(() => {
    // Initialize FingerprintJS and get the visitorId
    const getFingerprint = async (localBotPrint?: string) => {
      let visitorId = localBotPrint;
      if (!visitorId) {
        // Load the FingerprintJS agent asynchronously.
        const fp = await FingerprintJS.load();
        // Get the visitor identifier when it’s ready.
        const result = await fp.get();
        // This is the visitor identifier:
        visitorId = result.visitorId;
        localStorage.setItem('_mto_xb_', visitorId); 
      }
      dispatch(botSetFingerprintAction({fingerprint: visitorId || ''}));
    };
    if (!fingerprint) {
      const localBotPrint = localStorage.getItem('_mto_xb_') || '';
      getFingerprint(localBotPrint);
    }
  }, [dispatch, fingerprint]);

  let feedbackItems: Array<{name:string; pos:boolean;}> = [];
  if (!!positiveFeedback && positiveFeedback.length > 0) {
    feedbackItems = [...feedbackItems, ...positiveFeedback.map((item: { name: string; }) => ({name: item.name, pos:true}))];
  }
  if (!!negativeFeedback && negativeFeedback.length > 0) {
    feedbackItems = [...feedbackItems, ...negativeFeedback.map((item: { name: string; }) => ({name: item.name, pos:false}))];
  }

  const dispatcher = {
    debouncedFetchBotAnswerRequest: _debounce((anontoken: string) => {
      dispatch(fetchBotAnswerRequestAction?.({anontoken}));
    }, 350),
    trackBotFeedback: (botRequestId: string, feedback: string, rating: number, queryTerm?: string, anonToken?: string) => {
      dispatch(trackBotFeedbackAction?.({ botRequestId, feedback, rating, queryTerm, anonToken }));
    },
    setBotResponse: (botAnswer: string, botRequestId: string) => dispatch(botResponseEndAction?.({response: botAnswer, request_id: botRequestId})),
    changeQueryTerm: (term: string) => {
      dispatch(changeQueryTermAction?.({ term }));
    }
  };

  if (!botSearchActive) {
    return <></>;
  }

  return (
    <Bx mb={1}>
      {!!headline && <Typo variant="h1" component="h2" dangerouslySetInnerHTML={{ __html: headline }} />}
      <Bx display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        style={{
          border: '1px solid transparent',
          borderRadius: 16,
          background:'rgb(230, 245, 251)',
          padding:'16px'
        }}
      >
        <Bx pr={2}>
          <Typo variant="h1" component="p">
            <FontAwesomeIcon icon={faMessageBot} />
          </Typo>
        </Bx>
        <Bx>
          {(!botWorking && !botAnswer) && <Typo variant="body1" component="p" style={{width:'100%',marginTop:'8px'}}>
            Geben Sie oben eine fachliche Frage ein und schicken Sie Ihre Anfrage per Eingabe-Taste (ENTER) oder den blauen Button ab.
            </Typo>}
          {(botWorking && !botAnswer) && <Typo variant="body1" component="p" style={{width:'100%',marginTop:'8px'}}>
            <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
            &nbsp;
            <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
            &nbsp;
            <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
          </Typo>}
          {!!botAnswer && <Markdown className={clsx(classes.markdown)} remarkPlugins={[remarkGfm]} linkTarget="_blank">{(botAnswer ?? '')}</Markdown>}
        </Bx>
      </Bx>
      {botRequestId &&
        <Bx my={2} display={'flex'}
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
        <Bx>
          <IconBtn
            onClick={() => {
              dispatcher.setBotResponse?.('', '');
              dispatcher.debouncedFetchBotAnswerRequest?.(fingerprint ?? '');
            }}
            size="small"
          >
            <FontAwesomeIcon icon={faRedo} />
          </IconBtn>
        </Bx>
        {botAnswer && !!feedbackItems && feedbackItems.length > 0 && feedbackItems.map((item, index) => {
          const itemColor = item.pos
            ? theme.palette.secondary.main
            : theme.palette.error.main;
          return (
            <Bx
              key={`feedback-index-${index}`}
              display="flex"
              justifyContent="center"
              alignItems="center"
              minWidth={36}
              px={1.5}
              py={.5}
              ml={1}
              mb={1}
              style={{
                cursor:'pointer',
                borderRadius:8,
                border:`1px solid ${botFeedback === item.name ? 'transparent' : itemColor}`,
                background: botFeedback === item.name ? itemColor : 'transparent',
                color: botFeedback === item.name ? 'white' : itemColor
              }}
              onClick={() => {
                dispatcher.trackBotFeedback?.(botRequestId, item.name, 1, searchObject?.queryTerm, fingerprint);
              }}
            >
              <Typo
                style={{
                  wordBreak:'keep-all'
                }}
                variant="body1"
              >{item.name}</Typo>
            </Bx>
        )})}
      </Bx>}
    </Bx>
  );
};
