export const api = Object.freeze({
  params: (url: string, args: any) => {
    for (let key in args) {
      url = url.replace('#{' + key + '}#', args[key]);
    }
    return url;
  },
  query: (url: string, params: any) => {
    let queryString = '';
    for (let key in params) {
      const value = params[key];
      if (value) {
        if (queryString) queryString += '&';
        queryString += key + '=' + value;
      }
    }
    if (queryString.length) return url + '?' + queryString;
    return url;
  },
  auth: {
    logout: '/uwao-api/core/auth/logout',
    integratedLogin: '/uwao-api/core/auth/integratedlogin',
    credentialLogin: '/uwao-api/core/auth/credentiallogin',
    oauth_discovery: 'https://login.microsoftonline.com/35a464c3-31c6-442a-a7f1-45b27214cd16/v2.0/.well-known/openid-configuration',
    registration: '/uwao-api/mto/profile/registration',
    register: '/uwao-api/mto/profile/register',
    registerExpert: '/uwao-api/mto/profile/registerexpert',
    validateToken: '/uwao-api/mto/profile/gettokenvalid',
    deactivateMails: '/uwao-api/mto/profile/deactivatemails',
    lostPassword: '/uwao-api/mto/profile/lostpassword',
    finishLostPassword: '/uwao-api/mto/profile/finishLostPassword',
    termsAccepted: '/uwao-api/mto/profile/termsaccepted',
    externalLinksAccepted: '/uwao-api/mto/profile/externallinksaccepted',
    alertRead: '/uwao-api/mto/profile/alertread',
    userprofileChangeDataVisibility: '/uwao-api/mto/profile/userprofilechangedatavisibility',
    userprofileChangeMessagingPermissions: '/uwao-api/mto/profile/userprofilechangemessagingpermissions',
    upcmpanon: '/uwao-api/mto/profile/upcmpanon',
    getallgroups: '/uwao-api/mto/profile/getallgroups',
  },
  categories: '/uwao-api/mto/content/categories',
  lists: {
    academicTitles: '/uwao-api/mto/profile/academicTitles',
  },
  search: {
    related: '/uwao-api/mto/v2/search/related',
    filter: '/uwao-api/mto/v2/search/filter',
    dynamic: '/uwao-api/mto/v2/search',
  },
  v1: {
    players: {
      autosuggest: '/uwao-api/mto/research/players/search/autosuggest',
      byparentid: '/uwao-api/mto/research/players/search/byparentid',
    },
    profile: {
      base: '/uwao-api/mto/profile/',
      myProfile: '/uwao-api/mto/profile/userprofile',
      byAlias: '/uwao-api/mto/profile/userprofilebyalias/#{alias}#',
      aliasById: '/uwao-api/mto/profile/userprofilealiasbyid/#{alias}#',
      fullNameByIds: '/uwao-api/mto/profile/userprofilefullnamebyids',
      myItems: '/uwao-api/mto/profile/user-items/#{userId}#',
      employees: '/uwao-api/mto/profile/employees/#{actorId}#',
      articles: '/uwao-api/mto/profile/articles/#{actorId}#',
      pendingtokens: '/uwao-api/mto/profile/pendingtokens',
      resettokenrequest: '/uwao-api/mto/profile/tokenrequest/#{tokenId}#',
      resendnotifications: '/uwao-api/mto/profile/resendnotifications/#{email}#',
      resendnotificationsbyarticle: '/uwao-api/mto/profile/resendnotificationsbyarticle/#{contentId}#/#{contentType}#/#{eventType}#',
      updateProfile: '/uwao-api/mto/profile/userprofile',
      updateAvatar: '/uwao-api/mto/profile/userprofileavatar',
      updateKeyVisual: '/uwao-api/mto/profile/userprofilekeyvisual',
      changePassword: '/uwao-api/mto/profile/changepassword',
      terminateAccount: '/uwao-api/mto/profile/terminate',
      dsgvoExport: '/uwao-api/mto/profile/export',
      memorylist: '/uwao-api/mto/profile/memorylist',
    },
  },
  v2: {
    landingpage: '/uwao-api/mto/v2/landingpage',
    landingpageDetail: '/uwao-api/mto/v2/landingpage/#{typeId}#/#{alias}#',
    detail: '/uwao-api/mto/v2/detail/#{type}#/#{alias}#',
    trackbotfeedback: '/uwao-api/mto/v2/detail/trackbotfeedback',
    anonymous_search: '/uwao-api/mto/v2/search/botsearch',
    voting: '/uwao-api/mto/v2/detail/voting',
    meetings: {
      base: '/uwao-api/mto/v2/meeting',
      adhoc: '/uwao-api/mto/v2/meeting/adhoc/#{parentId}#',
      parent: '/uwao-api/mto/v2/meeting/parent/#{parentId}#',
      commitment: '/uwao-api/mto/v2/meeting/commitment',
      cancel: '/uwao-api/mto/v2/meeting/cancel',
      ics: '/uwao-api/mto/v2/meeting/ics/#{meetingId}#',
      inviteAdditional: '/uwao-api/mto/v2/meeting/invite-additional',
      meetingInvitation: '/uwao-api/mto/v2/meeting/#{id}#/meetinginvitation/#{participantId}#',
    },
    startup: '/uwao-api/mto/v2/startup',
    mail: '/uwao-api/mto/v2/mail',
    notifications: '/uwao-api/mto/v2/notifications',
    news: '/uwao-api/mto/v2/news/#{skip}#/#{limit}#/#{queryTerm}#/#{allConcepts}#/#{querySort}#',
    communication: {
      prepareAttachments: '/uwao-api/mto/v2/communication/#{id}#/prepare',
      finalizeUpload: '/uwao-api/mto/v2/communication/#{id}#/finalize',
      files: '/uwao-api/mto/v2/communication/#{id}#/files',
      downloadFiles: '/uwao-api/mto/v2/communication/#{id}#/files/download',
      leave: '/uwao-api/mto/v2/communication/#{id}#/leave',
      signalr: '/uwao-api/core/event-hub',
      base: '/uwao-api/mto/v2/communication',
      byId: '/uwao-api/mto/v2/communication/#{id}#',
      onetoone: '/uwao-api/mto/v2/onetoone',
      fetchonetoone: '/uwao-api/mto/v2/onetoone/#{partnerId}#',
      readMessage: '/uwao-api/mto/v2/communication/#{id}#/read/#{timestamp}#',
      unreadMessagesForId: '/uwao-api/mto/v2/communication/#{id}#/unread',
      unreadMessagesForTypes: '/uwao-api/mto/v2/communication/unread',
      participation: '/uwao-api/mto/v2/communication/#{id}#/participation',
      participants: '/uwao-api/mto/v2/communication/#{id}#/participants',
      removeParticipant: '/uwao-api/mto/v2/communication/#{id}#/participants/#{participantId}#',
      roles: '/uwao-api/mto/v2/communication/#{id}#/roles',
      messages: '/uwao-api/mto/v2/communication/#{id}#/messages',
      message: '/uwao-api/mto/v2/communication/#{id}#/messages/#{messageId}#',
      voteMessage: '/uwao-api/mto/v2/communication/#{id}#/messages/#{messageId}#/vote/#{vote}#',
      conferenceMessage: '/uwao-api/mto/v2/communication/#{id}#/messages/conference?title=#{title}#',
      acceptInvitation: '/uwao-api/mto/v2/communication/#{id}#/invitation/accept',
      participantInvitation: '/uwao-api/mto/v2/communication/#{id}#/invitation/#{participantId}#',
      requestResponse: '/uwao-api/mto/v2/communication/#{id}#/request/#{participantId}#',
      request: '/uwao-api/mto/v2/communication/#{id}#/request',
      role: '/uwao-api/mto/v2/communication/#{id}#/role/#{participantId}#',
      administrator: '/uwao-api/mto/v2/communication/#{id}#/administrators/#{participantId}#',
      declineInvitation: '/uwao-api/mto/v2/communication/#{id}#/invitation/decline',
      joinconference: '/uwao-api/mto/v2/communication/conference/join?messageId=#{messageId}#',
      updateEmotes: '/uwao-api/mto/v2/communication/#{id}#/messages/#{messageId}#/emote/#{emoji}#',
      toggleFileWriteProtected: '/uwao-api/mto/v2/communication/#{id}#/toggleAssetWriteProtect',
      adminCommunications: '/uwao-api/mto/v2/communication/admincommunications',
    },
  },
  flow: {
    create: {
      idea: '/uwao-api/mto/impulse/ideas',
      qna: '/uwao-api/mto/impulse/qna',
      requisition: '/uwao-api/mto/impulse/requisitions',
      workgroup: '/uwao-api/mto/v2/workgroup',
      project: '/uwao-api/mto/v2/project',
      meeting: '/uwao-api/mto/v2/meeting',
      activitystream: '/uwao-api/mto/v2/activitystream/#{typeId}#/#{elementId}#',
    },
    update: {
      idea: '/uwao-api/mto/impulse/ideas',
      qna: '/uwao-api/mto/impulse/qna',
      requisition: '/uwao-api/mto/impulse/requisitions',
      workgroup: '/uwao-api/mto/v2/workgroup',
      project: '/uwao-api/mto/v2/project',
    },
    getactortypeconfigs: '/uwao-api/mto/research/players/search/getactortypeconfigs',
  },
});
