import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IDetailComponent } from '..';
import { ConnectedCommunicationActionCanvas } from '../../communication/action-canvas';
import { ConnectedMessages } from '../../communication/messages';
import { CommunicationHeader, CommunicationTab, ConnectedHeader, GetMessagesRequest, ICommunicationAction, Tab } from '../../../components/communication/header';
import { ConnectedCommunicationInput } from '../../communication/input';
import { AppFrameContent, AppFrameHeader } from '../../../components/frame';
import { AppFrameFooter } from '../../../components/frame/footer';
import {
  setActionCanvas,
  setMessageDraftQuotes,
  setMessageDraftThreadRoot,
  setMessageDraftEdit,
  createConferenceMessageRequest,
  removeParticipantRequest,
  setParticipantModalAction,
  requestResponseRequest,
  revokeInvitationRequest,
  setAdministratorStatusRequest,
  updateInvitationRequest,
  voteMessageRequest,
  leaveCommunicationRequest,
  setUserMessageEmoteRequest,
  fetchParticipantsRequest,
  setActiveView,
  setActiveTab
} from '../../../data/reducer/communication';
import { Participants, PendingParticipationModal } from '../../../components/communication/participants';
import { ContentDetail } from '../content';
import { FormWorkgroup } from '../../../components/form/compositions/FormWorkgroup';
import { FormProject } from '../../../components/form/compositions/FormProject';
import { Anohana, Btn, Bx, Typo } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/pro-light-svg-icons';
import * as _ from 'lodash';
import { ConnectedFlowComponentFrame } from '../../flow';
import { CommunicationType } from '../../../model/communication/Communication';
import { ThingTypes } from '../../../model/ryve/Thing';
import { ConnectedFileManager } from '../../communication/file-manager';

import { FormCategories } from '../../../components/form/compositions/FormCategories';
import { GetExistingAttachments, TYPES, resolveTimestamp } from '../../../helper';
import { resetFormData, updateDetailRequest, updateFormData } from '../../../data/reducer/detail';
import { useCategoriesState } from '../../../data/sagas/categories';
import { IParticipantResolvedModel, ParticipationRequestStatus, ParticipationRequestType } from '../../../model/communication/Participant';
import { DashboardHeader } from '../../../components/header/dashboard';
import { PageElementsResolve } from '../../../components/page-element';
import { PageElementContentSelection, PageElementVisualization } from '../../../model/data/PageElement';
import { ConnectedEmptyContent } from '../../../components/communication';
import { ConnectedMeetingManager } from '../../communication/meeting-manager';
import { FramedContent } from '../../../components/framed-content';
import { useIsMobile } from '../../../helper/hooks/isMobile';
import { GeneralModal } from '../../../components/modals/GeneralModal';
import { InputModal } from '../../../components/modals/InputModal';
import { userprofileMemorylistRequestAction } from '../../../data/actions/foundation';
import { setFlowDataAction } from '../../../data/actions/flow';
import { IDetailItem } from '../../../model/detail';

const tabs: CommunicationTab[] = [
  {
    title: 'Chat',
    alias: 'main',
    type: 'messages',
    request: undefined
  },
  {
    title: 'Dateien',
    alias: 'files',
    type: 'files'
  },
  {
    title: 'Meetings',
    alias: 'meetings',
    type: 'view'
  }
];

const settingsTabs: Tab[] = [
  {
    title: 'Teilnehmende',
    alias: 'participants',
    type: 'view'
  }
];
const settingsTabsWithPermissions: Tab[] = [
  {
    title: 'Allgemein',
    alias: 'common',
    type: 'view'
  },
  {
    title: 'Teilnehmende',
    alias: 'participants',
    type: 'view'
  },
  {
    title: 'Kategorien',
    alias: 'categories',
    type: 'view'
  }
];

export const ConnectedParticipantModal: React.FC = () => {
  const dispatch = useDispatch();
  const id = useSelector(state => state.communication?.id);
  const participantModal = useSelector(state => state.communication?.participantModal);

  const setParticipantModal = (participant: IParticipantResolvedModel | undefined) => {
    dispatch(setParticipantModalAction({ participant }));
  };
  const onAcceptRequest = (participantId: string) => {
    id && dispatch(requestResponseRequest({ id, participantId, accept: true }));
  };
  const onDeclineRequest = (participantId: string) => {
    id && dispatch(requestResponseRequest({ id, participantId, accept: false }));
  };
  const onUpdateInvitation = (participantId: string, message: string) => {
    id && dispatch(updateInvitationRequest({ id, participantId, message }));
  };
  return (
    <PendingParticipationModal
      participation={participantModal}
      onAcceptRequest={() => {
        participantModal && onAcceptRequest(participantModal?.user);
        setParticipantModal(undefined);
      }}
      onDeclineRequest={() => {
        participantModal && onDeclineRequest(participantModal?.user);
        setParticipantModal(undefined);
      }}
      onUpdateInvitation={message => {
        participantModal && onUpdateInvitation(participantModal?.user, message);
        setParticipantModal(undefined);
      }}
      close={() => setParticipantModal(undefined)}
    />
  );
};

export const ConnectedParticipants: React.FC<{ onAddParticipants?: () => void }> = ({ onAddParticipants }) => {
  const participantsWorking = useSelector(state => state.communication?.participantsWorking);
  const participants = useSelector(state => state.communication?.participants);
  const participation = useSelector(state => state.communication?.participation);
  const communication = useSelector(state => state.communication?.communication);
  const id = useSelector(state => state.communication?.id);
  const userId = useSelector(state => state.foundation.profile?.userId);
  const dispatch = useDispatch();
  const history = useHistory();
  const setParticipantModal = (participant: IParticipantResolvedModel | undefined) => {
    dispatch(setParticipantModalAction({ participant }));
  };
  const onAcceptRequest = (participantId: string) => {
    id && dispatch(requestResponseRequest({ id, participantId, accept: true }));
  };
  const onDeclineRequest = (participantId: string) => {
    id && dispatch(requestResponseRequest({ id, participantId, accept: false }));
  };
  const onUpdateInvitation = (participantId: string, message: string) => {
    id && dispatch(updateInvitationRequest({ id, participantId, message }));
  };

  return (
    <>
      <Participants
        addButtonClicked={onAddParticipants}
        working={participantsWorking}
        participants={_.sortBy(participants, 'userResolved.content.fullName')}
        roles={communication?.roles}
        permissions={participation?.permissions}
        showInvites={communication?.allowInvites && participation?.permissions?.manageInvites}
        showRequests={communication?.allowRequests && participation?.permissions?.manageRequests}
        kickParticipants={participation?.permissions?.kickParticipants}
        currentUser={userId}
        onRemoveParticipant={participantId => {
          id && dispatch(removeParticipantRequest({ id, participantId }));
        }}
        onMakeAdmin={participantId => id && dispatch(setAdministratorStatusRequest({ id, participantId, admin: true }))}
        onRemoveAdmin={participantId => id && dispatch(setAdministratorStatusRequest({ id, participantId, admin: false }))}
        onRevokeInvitation={participantId => id && dispatch(revokeInvitationRequest({ id, participantId }))}
        onAcceptRequest={onAcceptRequest}
        onDeclineRequest={onDeclineRequest}
        onUpdateInvitation={onUpdateInvitation}
        onLeave={() => id && dispatch(leaveCommunicationRequest({ id, history, navigateTo: '/coop/groups-projects' }))}
        onSetModalParticipation={setParticipantModal}
      />
    </>
  );
};

export interface ICommunicationSettingsProps {
  hasEditPermissions?: boolean;
  type: string;
  formData: any;
  categories: any;
  config: any;
  close: () => void;
  updateFormData: (formData: any) => void;
  resetFormData: () => void;
  save: () => void;
}
export const CommunicationSettings: React.FC<ICommunicationSettingsProps> = ({
  hasEditPermissions,
  type,
  formData,
  categories,
  config,
  close,
  updateFormData,
  resetFormData,
  save
}) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const tabs = useRef(hasEditPermissions ? settingsTabsWithPermissions : settingsTabs);

  const activeTab = useSelector(state => state.communication?.activeTab);

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [addFlowActive, setAddFlowActive] = useState(false);

  useEffect(() => {
    if (!activeTab) {
      dispatch(setActiveTab({tab: tabs.current[0]}));
    }
  }, [dispatch, activeTab, tabs]);

  if (addFlowActive) {
    return <ConnectedFlowComponentFrame alias={'add-participants'} close={() => setAddFlowActive(false)} />;
  }

  const communicationActions: Array<ICommunicationAction> = [
    {
      onClick: () => save(),
      icon: <FontAwesomeIcon icon={faSave} />,
      specificType: 'Btn',
      title: 'Speichern',
      disabled: activeTab?.alias === 'participants',
      color: 'success',
      size: 'large',
      variant: 'contained'
    },
    {
      onClick: () => {
        if (hasEditPermissions && activeTab?.alias !== 'participants') {
          setCancelModalOpen(true);
        } else {
          close();
        }
      },
      icon: <FontAwesomeIcon icon={faTimes} />,
      specificType: 'Btn',
      title: 'Abbrechen',
      color: 'error',
      size: 'large',
      variant: 'outlined'
    }
  ];
  const cancelAction = () => {
    setCancelModalOpen(true);
    close();
    resetFormData();
  };

  return (
    <>
      <AppFrameHeader>
        <ConnectedHeader
          backButtonAction={isMobile ? () => close() : undefined}
          actions={communicationActions}
          tabs={tabs.current.map(t => t.title)}
          title="Einstellungen"
          selectTab={index => dispatch(setActiveTab({tab: tabs.current[index]}))}
          selectedTab={tabs.current.map(t => t.title).indexOf(activeTab?.title || '') >= 0 ? tabs.current.map(t => t.title).indexOf(activeTab?.title || '') : 0}
          isSettings={true}
        />
      </AppFrameHeader>
      <AppFrameContent>
        <ConnectedParticipantModal />
        {activeTab?.alias === 'common' && (
          <FramedContent>
            {type === ThingTypes.Group && <FormWorkgroup headline="" subtitle="" formData={formData} onChange={updateFormData} />}
            {type === ThingTypes.Project && <FormProject headline="" subtitle="" formData={formData} onChange={updateFormData} />}
          </FramedContent>
        )}
        {activeTab?.alias === 'participants' && (
          <FramedContent>
            <ConnectedParticipants onAddParticipants={() => setAddFlowActive(true)} />
          </FramedContent>
        )}
        {activeTab?.alias === 'categories' && (
          <FramedContent>
            <FormCategories headline="" subtitle="" config={config} formData={formData} categories={categories} onChange={updateFormData} />
          </FramedContent>
        )}
        <Anohana open={cancelModalOpen} transition="zoom" disableScrollLock fullWidth maxWidth="sm" onClose={() => setCancelModalOpen(false)}>
          <Bx p={2.5}>
            <Bx justifyContent="center" mb={3}>
              <Typo variant="h4" component="span">
                Wollen Sie wirklich abbrechen? Alle Änderungen gehen dabei verloren.
              </Typo>
            </Bx>
            <Bx display="flex" mt={3}>
              <Bx>
                <Btn variant="contained" color="success" size="large" onClick={() => setCancelModalOpen(false)}>
                  Zurück
                </Btn>
              </Bx>
              <Bx ml="auto">
                <Btn variant="outlined" color="error" size="large" onClick={cancelAction}>
                  Abbrechen bestätigen
                </Btn>
              </Bx>
            </Bx>
          </Bx>
        </Anohana>
      </AppFrameContent>
    </>
  );
};

export const ChatTab: React.FC<{
  id: string;
  alias: string;
  request?: GetMessagesRequest;
  inlineThreading?: boolean;
  detailItem?: IDetailItem;
}> = ({
  id,
  alias,
  request,
  inlineThreading,
  detailItem,

}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <FramedContent
      height="100%"
      minHeight="100%"
      innerBoxProps={{
        minHeight: '100%',
        height: '100%'
      }}
    >
      <ConnectedMessages
        isPublic={detailItem?.content?.public ?? true}
        isListed={detailItem?.content?.listed ?? false}
        renderEmptyContent={
          alias === 'main' ? () => <ConnectedEmptyContent /> : undefined
        }
        alias={alias}
        request={request}
        autoWidth
        quoteClicked={message => {
          dispatch(setMessageDraftQuotes({ quotes: message, id: alias }));
        }}
        emoteClicked={(message, emoji) => {
          dispatch(setUserMessageEmoteRequest({ target: message, emoji }));
        }}
        threadingClicked={message => {
          if (!inlineThreading) {
            // setze dessen für das Input feld im actioncanvas
            dispatch(setMessageDraftThreadRoot({ id: 'actioncanvas', threadRoot: message }));
            // öffne actioncanvas und setze dessen threadRoot
            dispatch(setActionCanvas({ open: true, threadRoot: message }));
          } else {
            // setze threadRoot für main input
            dispatch(setMessageDraftThreadRoot({ id: alias, threadRoot: message }));
          }
        }}
        editMessage={message => {
          dispatch(setMessageDraftEdit({ id: alias, message }));
        }}
        voteDownClick={message => {
          dispatch(voteMessageRequest({ communicationId: id, alias: alias, messageId: message.id, up: false }));
        }}
        voteUpClick={message => {
          dispatch(voteMessageRequest({ communicationId: id, alias: alias, messageId: message.id, up: true }));
        }}
        shareClicked={message => {
          dispatch(
            setFlowDataAction({
              alias: 'share',
              flowData: {
                location: history.location,
                itemData: {
                  sharedItem: message,
                  contextItem: detailItem
                }
              }
            })
          );
          history.push(history.location.pathname + '/flow' + history.location.search);
        }}
      />
    </FramedContent>
  );
};

export const ResearchTab = () => {
  const [searchObject, setSearchObject] = useState<any>({});
  const [filterVisible, setFilterVisible] = useState(false);

  const configRef = useRef({
    alias: 'research',
    pageElements: [
      {
        pageElementTitle: 'Empfehlungen aus dem Magazin',
        pageElementMaxResults: 3,
        pageElementSort__: 'score_desc',
        pageElementContentSelection__: PageElementContentSelection.SEARCH,
        pageElementVisualization__: PageElementVisualization.LIST,
        pageElementThingDescriptions: [ThingTypes.Blog, ThingTypes.Wiki, ThingTypes.BestPractice, ThingTypes.Collection]
      },
      {
        pageElementTitle: 'Empfehlungen aus dem Forum',
        pageElementMaxResults: 3,
        pageElementSort__: 'score_desc',
        pageElementContentSelection__: PageElementContentSelection.SEARCH,
        pageElementVisualization__: PageElementVisualization.LIST,
        pageElementThingDescriptions: [ThingTypes.QnA, ThingTypes.Idea, ThingTypes.Requisition]
      },
      {
        pageElementTitle: 'Empfehlungen aus Personen und Akteure',
        pageElementMaxResults: 3,
        pageElementSort__: 'score_desc',
        pageElementContentSelection__: PageElementContentSelection.SEARCH,
        pageElementVisualization__: PageElementVisualization.LIST,
        pageElementThingDescriptions: [ThingTypes.Actors]
      }
    ]
  });
  return (
    <>
      <DashboardHeader
        alias="project-research"
        placeholder="Inhalte finden"
        routeToSearch={false}
        canCreateContent={false}
        filterItem={{}}
        filterAlias={''}
        filterVisible={filterVisible}
        filterWorking={false}
        filterSwitch={false}
        searchObject={searchObject}
        searchTypes={[]}
        selectedSearch={''}
        changeQuerySortAction={sortId => setSearchObject({ ...searchObject, sortId })}
        changeQueryTerm={queryTerm => setSearchObject({ ...searchObject, queryTerm })}
        resetSearchObject={() => setSearchObject({})}
        showFilterAction={() => setFilterVisible(true)}
        hideFilterAction={() => setFilterVisible(false)}
      />
      <FramedContent>
        <Bx>
          <PageElementsResolve prefix={configRef.current.alias} pageElements={configRef.current.pageElements} />
        </Bx>
      </FramedContent>
    </>
  );
};

export const CommunicationDetail: React.FC<IDetailComponent> = ({ isMobile, isScrolled, setIsScrolled, canEdit }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const inlineThreading = useSelector(state => state.communication?.communication?.inlineThreading);
  const allowInvites = useSelector(state => state.communication?.communication?.allowInvites && state.communication?.participation?.permissions?.allowInvites);

  const allowConferencing = useSelector(state => {
    const communication = state.communication?.communication;
    if (!communication) return false;

    // no allowed
    if (!communication.allowConferencing) return false;

    // no participation
    const participation = state.communication?.participation;
    if (!participation) return false;

    // group - OK
    if (communication.type === CommunicationType.GROUP) return participation.permissions.allowConferencing;

    // single - own pending
    if (participation.status === ParticipationRequestStatus.PENDING) return false;

    // find other participation
    var userId = state.foundation.profile?.userId;
    if (!userId) return false;
    var otherParticipation = communication.participants.find(p => p.user !== userId);
    if (!otherParticipation) return false;
    if (otherParticipation.status === ParticipationRequestStatus.PENDING) return false;

    return participation.permissions.allowConferencing;
  });
  
  const type = useSelector(state => state.communication?.communication?.type);

  const oneToOneStatus = useSelector(state => {
    if (type !== CommunicationType.ONETOONE) return undefined;
    
    const ownParticipation = state.communication.participation;
    if (ownParticipation?.status !== ParticipationRequestStatus.ACCEPTED || ownParticipation?.type !== ParticipationRequestType.CREATOR) return undefined;

    const partnerParticipation = state.communication.participants?.find(p => p.user !== ownParticipation.user);
    if (partnerParticipation?.status !== ParticipationRequestStatus.PENDING) return undefined;

    return {
      partnerParticipation: partnerParticipation,
      onResendInvitationClick: () => {
        setInvitationText(partnerParticipation.message);
        setModalState('resendInvitation');
      },
      onRevokeInvitationClick: () => setModalState('withdrawInvitation')
    }
  })

  const config = useSelector(state => state.detail.config);
  const working = useSelector(state => state.detail.working);
  const detailItem = useSelector(state => state.detail.item);
  const detailId = useSelector(state => state.detail.item?._id);
  const detailType = useSelector(state => state.detail.item?.type);
  const detailTitle = useSelector(state => state.detail.item?.content?.title);

  const detailDescription = useSelector(state => state.detail.item?.content?.description);
  const attachmentsSubD = useSelector(state => state.detail.item?.content?.attachmentsSubD);
  const detailCategories = useSelector(state => state.detail.item?.categories);
  const avatarResolved = useSelector(state => state.detail.item?.content?.avatarResolved);
  const keyvisualResolved = useSelector(state => state.detail.item?.content?.keyvisualResolved);

  const listed = useSelector(state => state.detail?.item?.content?.listed);
  const parentListed = useSelector(state => state.detail?.item?.content?.parentThingResolved?.[0]?.content?.listed);
  const unlistedGroup = (detailType === TYPES.GROUP || detailType === TYPES.PROJECT) && listed === false;
  const unlistedParent = detailType === TYPES.MEETING && parentListed === false;

  const memoryList = useSelector(state => state.foundation?.profile?.memoryList);
  const memoryIds = memoryList?.map(m => m.elementId);
  const booked = !!memoryIds && !!detailId ? memoryIds.includes(detailId) : false;

  const activeView = useSelector(state => state.communication?.activeView);

  const categories = useCategoriesState();
  let formData = useSelector(state => state.detail?.formData);
  if (!formData || formData._id !== detailId) {
    const attachments = attachmentsSubD && attachmentsSubD.length > 0 ? GetExistingAttachments([...attachmentsSubD]) : [];
    const existingAvatar = avatarResolved ? GetExistingAttachments([avatarResolved]) : [];
    const existingKeyvisual = keyvisualResolved ? GetExistingAttachments([keyvisualResolved]) : [];
    formData = {
      _id: detailId,
      avatar: existingAvatar,
      keyvisual: existingKeyvisual,
      itemData: {
        title: detailTitle,
        description: detailDescription
      },
      attachments: [...(attachments || [])],
      categories: detailCategories?.map(cat => cat.categoryId)
    };
    dispatch(updateFormData?.({ formData }));
  }
  const dispatcher = {
    updateFormData: (formData: any) => dispatch(updateFormData?.({ formData })),
    resetFormData: () => dispatch(resetFormData?.({})),
    updateDetailRequest: () => dispatch(updateDetailRequest?.({ history })),
    memoryClick: () => dispatch(userprofileMemorylistRequestAction({
      memoryItem: { elementId: detailId || '', type: 'thing', typeId: detailType || ''},
      type: booked ? 'DELETE' : 'POST'
    })),
    shareClick: type === CommunicationType.ONETOONE || unlistedGroup || unlistedParent
      ? undefined
      : () => {
        dispatch(setFlowDataAction({
          alias: 'share',
          flowData: {
            location: history.location,
            itemData: { sharedItem: detailItem }
          }
        }));
        history.push(history.location.pathname + '/flow' + history.location.search);
      },
  };
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const [modalState, setModalState] = useState<'closed' | 'withdrawInvitation' | 'resendInvitation'>('closed');
  const [invitationText, setInvitationText] = useState('');

  useEffect(() => {
    if (type === CommunicationType.ONETOONE) {
      detailId && dispatch(fetchParticipantsRequest({ id: detailId }));
    }
  }, [dispatch, detailId]);

  const _setActiveView = (view: string) => {
    dispatch(setActionCanvas({ open: false }));
    dispatch(setActiveView({ view }));
  }

  return (
    <>
      {activeView === 'add-participants' && <ConnectedFlowComponentFrame alias={'add-participants'} close={() => _setActiveView('chat')} />}
      {activeView === 'settings' && detailType && (
        <CommunicationSettings
          type={detailType}
          hasEditPermissions={canEdit}
          formData={formData}
          categories={categories}
          config={config}
          close={() => _setActiveView('chat')}
          updateFormData={dispatcher.updateFormData}
          resetFormData={dispatcher.resetFormData}
          save={dispatcher.updateDetailRequest}
        />
      )}
      {activeView === 'content' && (
        <ContentDetail chatButton={{}} isMobile={isMobile} setIsScrolled={setIsScrolled} isScrolled={isScrolled} backButtonLink={() => _setActiveView('chat')} canEdit={false} />
      )}
      {activeView === 'chat' && detailId && (
        <>
          <AppFrameHeader>
            <CommunicationHeader
              openAddParticipants={allowInvites && type === CommunicationType.GROUP ? () => _setActiveView('add-participants') : undefined}
              openSettings={type === CommunicationType.GROUP ? () => _setActiveView('settings') : undefined}
              openInfo={type === CommunicationType.GROUP ? () => { _setActiveView('content'); } : undefined}
              working={working}
              title={detailTitle}
              tabs={tabs.filter(tab => {
                if (tab.alias === 'research' && detailType !== ThingTypes.Project) {
                  return false;
                }
                if (tab.alias === 'meetings' && detailType === ThingTypes.OneToOne) {
                  return false;
                }
                return true;
              })}
              allowConferencing={allowConferencing}
              onConferenceClick={() => {detailType === ThingTypes.OneToOne
                  ? dispatch(createConferenceMessageRequest({ id: 'conference', communicationId: detailId }))
                  : history.push(history.location.pathname + '/meet' + history.location.search);
              }}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              oneToOneStatus={oneToOneStatus}
              shareClick={dispatcher.shareClick}
              memoryClick={dispatcher.memoryClick}
              booked={booked}
            />
          </AppFrameHeader>
          <AppFrameContent>
            {detailId && selectedTab.alias === 'main' &&
              <ChatTab
                id={detailId}
                alias={selectedTab.alias}
                request={selectedTab.request}
                inlineThreading={inlineThreading}
                detailItem={detailItem ?? undefined}
              />
            }
            {detailId && selectedTab.alias === 'meetings' && <ConnectedMeetingManager />}
            {detailId && selectedTab.type === 'files' && <ConnectedFileManager />}
            {detailId && selectedTab.type === 'view' && selectedTab.alias === 'research' && <ResearchTab />}
            {detailId && <ConnectedCommunicationActionCanvas threadOnly headline="Thread" detailItem={detailItem ?? undefined} />}
          </AppFrameContent>

          <AppFrameFooter>
            {detailId && selectedTab.alias === 'main' && (
              <ConnectedCommunicationInput
                displayThreadRoot={inlineThreading}
                removeThreadRoot={() => {
                  dispatch(setMessageDraftThreadRoot({ id: selectedTab.alias, threadRoot: undefined }));
                }}
                small={false}
                inputId={selectedTab.alias}
              />
            )}
          </AppFrameFooter>

          <GeneralModal
            open={modalState === 'withdrawInvitation'}
            headline='Wollen Sie die Einladung zum Chat wirklich zurückziehen?'
            content='Mit dem Bestätigen wird die Einladung zurückgezogen. Ein erneutes Einladen ist jederzeit möglich'
            commitText='Einladung zurückziehen'
            cancel={() => setModalState('closed')}
            close={() => setModalState('closed')}
            commit={() => {
              const participantId = oneToOneStatus?.partnerParticipation?.user;
              participantId && dispatch(revokeInvitationRequest({ 
                id: detailId, 
                participantId: participantId
              }))
              setModalState('closed');
              history.replace('/coop/chat');
            }}
          />
          <InputModal
            open={modalState === 'resendInvitation'}
            headline='Einladung erneut senden'
            content={'Sie haben die aktuelle Einladung ' + resolveTimestamp(oneToOneStatus?.partnerParticipation.modifiedAt, false, false, true, true) + ' gesendet. Wollen Sie wirklich erneut eine Einladung senden?'}
            inputText={invitationText}
            commitText='Senden'
            onInputTextChange={(text: string) => setInvitationText(text)}
            cancel={() => setModalState('closed')}
            close={() => setModalState('closed')}
            commit={() => {
              const participantId = oneToOneStatus?.partnerParticipation?.user;
              participantId && dispatch(updateInvitationRequest({
                id: detailId, 
                participantId: participantId, 
                message: invitationText
              }))
              setModalState('closed');
            }}
          />
        </>
      )}
    </>
  );
};
