import React, { FC, useState } from 'react';
import { Ava, Btn, Bx, IconAva, IImageGalleryProps, Link } from '@curry-group/mui-curcuma';
import { Menu, MenuItem } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCameraHome } from '@fortawesome/pro-light-svg-icons';
import { IConferenceInformation, IMessageModel, IMessageReferences } from '../../../model/communication/Message';
import useTheme from '@material-ui/core/styles/useTheme';
import { MessageQuote } from './quote';
import { assetUrl, getDetailRoute, getMessageRoute, getProfileRouteByUserId, getUniqueArray, hashCode, ListAvatarColors, renderListItem } from '../../../helper';
import { AssetAttachment } from '../attachments';
import { MessageInteractionBar } from './interaction-bar';
import { MessageHeader } from './header';
import { MessageContent } from './content';
import { IAsset, isImage } from '../../../model/ryve/Asset';
import { MessageImageGallery } from './gallery';
import { useIsMobile } from '../../../helper/hooks/isMobile';
import { ChatMessage } from '../../chat-message';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { IThing, transformForList } from '../../../model/ryve/Thing';
import { ISearchEntry } from '../../../model/search/SearchEntry';
import Badge from '@material-ui/core/Badge';

export interface IMessageProps {
  id: string;
  avatarSrc?: string;
  name: string;
  timestamp: string;
  modified?: boolean;
  content: string;
  deleted?: boolean;
  width?: number;
  attachments?: IAsset[];
  createdBy: string;

  attachedThing?: IThing<ISearchEntry>; 

  isOwnMessage: boolean;

  canEdit?: boolean;
  onEditClicked?: () => void;
  canDelete?: boolean;
  onDeleteClicked?: () => void;

  quotes?: IMessageModel;
  allowQuotes?: boolean;
  quoteClicked?: () => void;
  emoteClicked?: (emoji:string) => void;

  allowThreading?: boolean;
  numThreadMessages?: number;
  threadingClicked?: () => void;
  asInlineThread?: boolean;

  allowVoting?: boolean;
  userVoting?: number;
  voteUpClick?: () => void;
  voteDownClick?: () => void;
  numVotes?: number;

  onMessageRead?: () => void;
  onJoinConference?: (message?: IMessageModel) => void;
  abbreviation?: string;
  conferenceInfo?: IConferenceInformation;
  references?: IMessageReferences;
  onReferenceSelected?: (type: string, data: any) => void;

  emotes?: {};
  activeParticipants?: any;

  newestAnswer: IMessageModel | null;
  userStatus?: string;

  shareAllowed?: boolean;
  shareClicked?: () => void;
}

export const Message: React.FC<IMessageProps> = ({
  id,
  width,
  avatarSrc,
  name,
  timestamp,
  modified,
  attachments,
  attachedThing,
  content,
  deleted,
  isOwnMessage,
  allowThreading,
  allowVoting,
  voteUpClick,
  userVoting,
  voteDownClick,
  threadingClicked,
  numThreadMessages,
  asInlineThread,
  quotes,
  numVotes,
  allowQuotes,
  quoteClicked,
  emoteClicked,
  canDelete,
  onDeleteClicked,
  canEdit,
  onEditClicked,
  onMessageRead,
  onJoinConference,
  abbreviation,
  createdBy,
  conferenceInfo,
  references,
  onReferenceSelected,
  emotes,
  activeParticipants,
  newestAnswer,
  userStatus,
  shareAllowed,
  shareClicked
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const location = useLocation();
  const appconfig = useSelector(state => state.foundation.appconfig);
  const avatarDict = useSelector(state => state.foundation?.avatarsById);

  /* style */
  let bgColor = isOwnMessage ? theme.palette.primary.main : theme.palette.background.paper;
  let color = isOwnMessage ? theme.palette.primary.contrastText : '';

  if (deleted) {
    color = theme.palette.primary.contrastText;
    bgColor = theme.palette.grey[700];
  }

  const threadOffsetMargin = 24;

  const realWidth = Math.min(Math.max(width || 0, 60), 100);

  let boxWidth = '100%';
  let boxMarginLeft = '0px';
  let boxMarginRight = boxMarginLeft;

  if (asInlineThread) {
    boxWidth = `calc(100% - ${threadOffsetMargin * 2}px)`;
    boxMarginLeft = threadOffsetMargin + 'px';
    boxMarginRight = boxMarginLeft;
  }
  /* style */

  /* misc */
  const downloadAsset = (asset: IAsset) => {
    if (!asset._id) {
      return;
    }
    var a = document.createElement('a');
    a.href = assetUrl(asset._id) || '';
    a.setAttribute('download', asset.name + asset.extension);
    a.click();
  };
  const [anchorEl, setAnchorEl] = useState<{ target: HTMLElement } | null>(null);
  const [anchorEmote, setAnchorEmote] = useState<{ target: HTMLElement } | null>(null);

  const handleClick = (target: HTMLButtonElement) => {
    setAnchorEl({ target });
  };
  const handleClickEmote = (target: HTMLButtonElement) => {
    setAnchorEmote({ target });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseEmote = () => {
    setAnchorEmote(null);
  };
  /* misc */

  /* props */
  const hasVoting = allowVoting && !!voteDownClick && !!voteUpClick;
  const hasThreading = (allowThreading || conferenceInfo) && !!threadingClicked;
  const hasQuotes = allowQuotes && !!quoteClicked;

  let hasDropdown = false;
  if (deleted) {
    // hasDropdown = !!(canDelete && onDeleteClicked);
  } else {
    hasDropdown = !!(canDelete && onDeleteClicked) || !!(canEdit && onEditClicked) || !!(!deleted && hasQuotes && !conferenceInfo);
  }

  let galleryImages: IImageGalleryProps['images'] = [];
  if (attachments && attachments.length) {
    galleryImages = attachments
      .filter(attachment => isImage(attachment))
      .map(attachment => {
        return {
          thumbnail: { title: attachment.name, alt: attachment.name, image: assetUrl(attachment._id, true) || '', downloadImage: '' },
          fullImage: { title: attachment.name, alt: attachment.name, image: assetUrl(attachment._id) || '', downloadImage: '' }
        };
      });
  }
  /* props */
  const hash = hashCode(abbreviation ?? '');
  const colorIndex = hash % 4;
  const colorObject = ListAvatarColors[colorIndex];

  const CreatorIcon = (userStatus?: string) => {
    if (isOwnMessage) return <Bx mr={2} pt={0.5} flexGrow={1} flexShrink={0} flexBasis={asInlineThread ? 32 : 46} />;

    let iconContent = !!userStatus && userStatus === 'to_check_signature_employer'
      ? <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          color={'error'}
          title={'User noch nicht validiert.'}
          variant='dot'
        >
          <Ava style={{ color: colorObject.color, fontSize: '14px', fontWeight: 500, backgroundColor: colorObject.bg }} size={asInlineThread ? 'small' : 'small'} src={avatarSrc}>
            {abbreviation}
          </Ava>
        </Badge>
      : <Ava style={{ color: colorObject.color, fontSize: '14px', fontWeight: 500, backgroundColor: colorObject.bg }} size={asInlineThread ? 'small' : 'small'} src={avatarSrc}>
          {abbreviation}
        </Ava>;

    if (conferenceInfo) {
      iconContent = (
        <IconAva variant="circular" size={asInlineThread ? 'small' : 'small'} color="secondary" inverted={true}>
          <FontAwesomeIcon icon={faCameraHome} />
        </IconAva>
      );
    }

    if (references && references.meeting) {
      iconContent = (
        <IconAva variant="circular" size={asInlineThread ? 'small' : 'small'} color="secondary" inverted={true}>
          <FontAwesomeIcon icon={faCalendarAlt} />
        </IconAva>
      );
    }
    return (
      <Bx mr={2} pt={0.5}>
        {iconContent}
      </Bx>
    );
  };

  let attachedItems = !!attachedThing
    ? transformForList(
        [attachedThing].map(item => {
          return {
            _source: item,
            _id: item._id,
            _score: 0
          };
        })
      )
    : [];
  let quoteOriginLink = '';
  let quoteOriginTitle = '';
  if (!!quotes && attachedItems.length > 0) {
    const quoteOriginItem = attachedItems[0];
    quoteOriginLink = getDetailRoute(quoteOriginItem, appconfig || {}, location) || '';
    if (!!quoteOriginLink) {
      const appendChar = quoteOriginLink.includes('?') ? '&' : '?';
      quoteOriginLink += `${appendChar}${getMessageRoute(quotes, quoteOriginItem)}`;
    }
    quoteOriginTitle = quoteOriginItem.title || '';
    attachedItems = [];
  }

  return (
    <Bx
      id={id}
      display="flex"
      position="relative"
      mb={isMobile ? 2 : 3}
      width={isMobile ? '92%' : boxWidth}
      style={{ marginLeft: isMobile ? (isOwnMessage ? '8%' : undefined) : boxMarginLeft, marginRight: isMobile ? (!isOwnMessage ? '8%' : undefined) : boxMarginRight }}
    >
      {!isMobile && CreatorIcon(userStatus)}

      <Bx width={isMobile ? '100%' : realWidth + '%'} className={'position-relative'}>
      {/* <Bx width={isMobile ? '100%' : realWidth + '%'}> */}
        <ChatMessage px={{ xs: 1.5, md: 2 }} pt={{ xs: 1.5, md: 0.75 }} pb={{ xs: 1, md: 1.25 }} bgcolor={bgColor} color={color}>
          <MessageHeader
            mobile={isMobile}
            isOwnMessage={isOwnMessage}
            isConference={!!conferenceInfo}
            references={references}
            isDeleted={deleted}
            isModified={modified}
            timestamp={timestamp}
            content={content}
            createdById={createdBy}
            createdByName={name}
            hasDropdown={hasDropdown}
            setDropdownAnchor={handleClick}
            isMeeting={!!references?.meeting}
          />

          {/* text content or conference or reference */}
          <MessageContent
            content={content}
            isOwnMessage={isOwnMessage}
            isDeleted={deleted}
            conferenceInfo={conferenceInfo}
            onJoinConference={onJoinConference}
            references={references}
            onReferenceSelected={onReferenceSelected}
            color={color}
          />

          {/* quote */}
          {!deleted && quotes &&
            <MessageQuote
              message={quotes}
              onJoinConference={() => onJoinConference?.(quotes)}
              references={references}
              onReferenceSelected={onReferenceSelected}
              quoteOriginLink={quoteOriginLink}
              quoteOriginTitle={quoteOriginTitle}
            />
          }

          {/* gallery */}
          {!deleted && !!(galleryImages && galleryImages.length) && <MessageImageGallery images={galleryImages} />}

          {/* all attachments */}
          {!deleted &&
            !!(attachments && attachments.length) &&
            attachments.map((attachment, index) => (
              <AssetAttachment key={'attachment-' + index}
                color={isOwnMessage ? 'text.primary' : undefined}
                mt={1}
                onDownload={downloadAsset}
                asset={attachment}
              />
            ))}
          {!deleted && attachedThing?.content && renderListItem(
            attachedItems[0],
            0,
            appconfig,
            location,
            avatarDict,
            undefined,
            isOwnMessage
          )}
          {(!references?.meeting || (!!conferenceInfo?.ended && !!numThreadMessages && numThreadMessages > 0)) && (
            <MessageInteractionBar
              hasVoting={hasVoting}
              hasThreading={hasThreading}
              hasQuotes={hasQuotes}
              isDeleted={deleted}
              isOwnMessage={isOwnMessage}
              numChildren={numThreadMessages}
              numVotes={numVotes}
              userVoting={userVoting}
              requestThread={() => threadingClicked?.()}
              requestQuote={() => quoteClicked?.()}
              voteUp={() => voteUpClick?.()}
              voteDown={() => voteDownClick?.()}
              newestAnswer={newestAnswer}
              shareAllowed={shareAllowed ?? false}
              shareMessage={shareClicked ?? (() => {})}
            />
          )}
          {!!emotes &&
            <MessageEmoteTeaser
              emotes={emotes}
              openEmotes={handleClickEmote}
            >
              👍💙2
            </MessageEmoteTeaser>}
        </ChatMessage>
      </Bx>
      {hasDropdown && !references?.meeting && (
        <MessageDropdown
          target={anchorEl?.target}
          close={handleClose}
          isDeleted={deleted}
          canDelete={canDelete}
          onDelete={() => {
            handleClose();
            onDeleteClicked?.();
          }}
          canEdit={canEdit}
          onEdit={() => {
            handleClose();
            onEditClicked?.();
          }}
          canQuote={hasQuotes}
          canThread={hasThreading}
          onQuote={() => {
            handleClose();
            quoteClicked?.();
          }}
          onEmote={(emoji:string) => {
            handleClose();
            emoteClicked?.(emoji);
          }}
        />
      )}
      {!!emotes && <MessageEmotes
        target={anchorEmote?.target}
        emotes={emotes}
        close={handleCloseEmote}
        activeParticipants={activeParticipants}
      />}
    </Bx>
  );
};

export interface IMessageDropdownProps {
  target?: any;
  close: () => void;

  isDeleted?: boolean;
  canDelete?: boolean;
  onDelete?: () => void;

  canEdit?: boolean;
  onEdit?: () => void;

  canQuote?: boolean;
  canThread?: boolean;
  onQuote?: () => void;
  onEmote: (emoji:string) => void;
}

const useStyles = makeStyles(theme => ({
  contextMenu: {
    padding: 0
  },
  emojiCount: {
    lineHeight: 1.4,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main
  },
  emojiButton: {
    margin: 0,
    marginRight: '2px',
    padding: '2px',
    fontSize: 'inherit',
    borderRadius: 0,
    minWidth: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  listUserEmoji: {
    paddingRight: '8px',
  },
  listUserName: {
    lineHeight: 1.4,
    fontFamily: theme.typography.fontFamily
  },
  emoteTeaser: {
    display: 'block',
    position: 'absolute',
    right: '10px',
    bottom: '-15px',
    margin: 0,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    background: 'white',
    color: theme.palette.primary.main,
    padding: '0 5px',
    cursor: 'pointer',
  }
}));

export const MessageDropdown: FC<IMessageDropdownProps> = ({ target, close, isDeleted, canDelete, onDelete, canEdit, onEdit, canQuote, canThread, onQuote, onEmote }) => {
  const classes = useStyles();
  if (!target) return <></>;
  return (
    <Menu className={clsx(classes.contextMenu)} anchorEl={target} keepMounted open={true} onClose={close}>
      {!isDeleted && canQuote && !!onEmote && <MenuItem key={'msg-drop-4'} onClick={() => onEmote('👍')}>
        <Btn className={clsx(classes.emojiButton)}>👍</Btn>
        {/* <Btn className={clsx(classes.emojiButton)} onClick={() => onEmote('💙')}>💙</Btn>
        <Btn className={clsx(classes.emojiButton)} onClick={() => onEmote('😀')}>😀</Btn>
        <Btn className={clsx(classes.emojiButton)} onClick={() => onEmote('🙂')}>🙂</Btn>
        <Btn className={clsx(classes.emojiButton)} onClick={() => onEmote('🙁')}>🙁</Btn> */}
      </MenuItem>}
      {!isDeleted && canEdit && !!onEdit && <MenuItem key={'msg-drop-2'} onClick={onEdit}>Bearbeiten</MenuItem>}
      {!isDeleted && canQuote && !!onQuote && <MenuItem key={'msg-drop-3'} onClick={onQuote}>Zitieren</MenuItem>}
      {!isDeleted && canDelete && !!onDelete && <MenuItem key={'msg-drop-1'} onClick={onDelete}>Löschen</MenuItem>}
      {/* {isDeleted && canDelete && !!onDelete && <MenuItem key={'msg-drop-9'} onClick={onDelete}>Endgültig löschen</MenuItem>} */}
    </Menu>
  );
};

export const MessageEmoteTeaser = props => {
  const classes = useStyles();
  const emotes = Object.values(props.emotes) as string[];
  const emotesUnique = getUniqueArray(emotes);
  return (<>
      {(!!emotes && emotes.length > 0) && <div className={clsx(classes.emoteTeaser)}>
        <div onClick={e => props.openEmotes?.(e.currentTarget)}>
          {(emotesUnique && emotesUnique.length > 0) && emotesUnique.map((emoji, idx) => (
            <span key={`msg-emote-${idx}`}>{emoji}</span>
          ))}
          {emotes.length > 1 && <span className={clsx(classes.emojiCount)} key={'msg-emote-count'}>&nbsp;{emotes.length}</span>}
        </div>
      </div>}
    </>
  );
};

export interface IMessageEmotesProps {
  target?: any;
  close: () => void;

  emotes: {};
  activeParticipants?: any;
}

export const MessageEmotes: FC<IMessageEmotesProps> = ({ target, close, emotes, activeParticipants }) => {
  const classes = useStyles();
  const location = useLocation();
  if (!target) return <></>;
  return (
    <Menu className={clsx(classes.contextMenu)} anchorEl={target} keepMounted open={true} onClose={close}>
      {(emotes && Object.keys(emotes).length > 0) && Object.keys(emotes).map((user, idx) => {
        let userName = user;
        if (activeParticipants) {
          if (activeParticipants[user]) {
            userName = activeParticipants[user];
          }
        }
        return (
        <MenuItem key={`msg-emote-${idx}`}>
          <Link underline="none" to={getProfileRouteByUserId(user) + (location ? '?ref=' + location?.pathname : '')}>
            <span className={clsx(classes.listUserEmoji)}>{emotes[user]}</span><span className={clsx(classes.listUserName)}>{userName}</span>
          </Link>
        </MenuItem>
      )})}
    </Menu>
  );
};