import { Ava, AvaDropZone, Bx, Btn, BtnList, DropZone, ExtendedListItem, IconAva, IconBtn, Input, LayoutImage, Link, ListWrapper, Typo, IBxProps, useBreakpoints, InputInfoText, SwitchControl } from '@curry-group/mui-curcuma';
import { faLinkedinIn, faXing } from '@fortawesome/free-brands-svg-icons';
import { OneToOneCommunicationStateButton } from './communicationButton';
import { OneToOneCommunicationPendingParticipationBanner } from './communicationBanner';
import { faBookmark as faBookmarkLight, faCookie, faCookieBite, faEdit, faFileExport, faFlaskPotion, faGlobe, faKey, faMobile, faPhoneOffice, faShare, faTimes, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, CircularProgress, Fade, Grid, Hidden } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { isFunction } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { api } from '../../data/api';
import { userprofileRestore, userprofileTermination, userprofileUpdateAvatar, userprofileUpdateKeyVisual, userprofileUpdatePassword } from '../../data/reducer/detail';
import { fetchRelatedItems, userprofileCommunicationRequest } from '../../data/reducer/userprofile';
import { userprofileUpdatePasswordReset } from '../../data/reducer/detail';
import { assetUrl, filterTreeById, GetActorIcon, hashCode, ListAvatarColors, PROFILE_TYPES, TYPES, TYPES_PROPS } from '../../helper';
import { validateLinkedIn, validatePhone, validateUrl, validateXing } from '../../helper/validator';
import { IProfile } from '../../model/profile';
import { IPasswordChange } from '../../model/profile/password';
import { FormInput } from '../form/controls/input';
import { ListWidget } from '../widget/list';
import _ from 'lodash';
import { FormCategorySelect } from '../form/controls/category-select';
import { ICategory } from '../../model/profile/category';
import { setFlowDataAction } from '../../data/actions/flow';
import { IPreviewableFile } from '../../model/profile/file';
import { EmployerSelect } from '../form/controls/employer-select';
import { IUserProfilePlayer } from '../../model/profile/player';
import { passwordValid } from '../../helper';
import { HeadlineContainer } from '../headline-container';
import { FramedContent } from '../framed-content';
import { FormControlLabel } from '@material-ui/core';
import { useAcademicTitles, useAcademicTitlesState } from '../../data/sagas/lists';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { TokenAdminComponent } from './tokenAdminComponent';
import { HoverProfilePictureDialog } from '../dialogs/hover-profile-picture';
import { buildAbbreviation } from '../../helper/user';
import { BlacklistInviteComponent } from './blacklistInviteComponent';
import { userprofileAlertReadRequestAction, userprofileMemorylistRequestAction } from '../../data/actions/foundation';
import { faBookmark, faHourglassClock } from '@fortawesome/pro-solid-svg-icons';
import { ThingTypes } from '../../model/ryve/Thing';
import { INotification } from '../../model/notifications';
import { ExpertRegistrationComponent } from './expertRegistrationComponent';
import Badge from '@material-ui/core/Badge';
import { fetchAdminParticipationsRequest } from '../../data/reducer/communication';
import { IListItem } from '../../model/list/ListItem';

const avaRight = { xs: 3 * 8, md: 7.5 * 8 };
const boxBorderRadius: IBxProps['borderRadius'] = 'borderRadius2';
const boxBgColor: IBxProps['bgcolor'] = 'background.paper';
const boxPy: IBxProps['py'] = { xs: 2, md: 5.5 };
const boxPx: IBxProps['px'] = { xs: 3, md: 7.5 };
const boxMb: IBxProps['mb'] = 5;

const ProfileBox = props => {
  return (
    <Bx position="relative" bgcolor={boxBgColor} borderRadius={boxBorderRadius} py={boxPy} px={boxPx} mb={boxMb} {..._.omit(props, ['borderRadius', 'bgcolor', 'py', 'px'])} />
  );
};

const LinkBehavior = props => {
  return <Link {...props} />;
};

export const UserProfileComponent: React.FC<{
  startInvitation?: (userId: string) => void;
  isScrolled?: boolean;
  onScroll?: (isScrolled: any) => void;
  onCancel?: () => void;
  isSettings?: boolean;
  isAdmin: boolean;
  isInvite: boolean;
  isExpertRegistration: boolean;
  isEditing?: boolean;
  profile: IProfile;
  setProfile: (p: IProfile) => void;
  files: Array<IPreviewableFile>;
  setFiles: (f: Array<IPreviewableFile>) => void;
  setEditMode: (setting: boolean) => void;
  setDisableSave: (setting: boolean) => void;
  openChatModal: () => void;
  openExternalLinkModal: () => void;
  changeDataVisibility: (toggleProp: string) => void;
  changeMessagingPermissions: (toggleProp: string) => void;
}> = settings => {
  const killPhrase = 'löschen';
  useAcademicTitles();

  const [bgImageLoaded, setBgImageLoaded] = React.useState(false);
  const { isScrolled, onScroll, isSettings, isAdmin, isInvite, isExpertRegistration } = { ...settings };
  const dispatch = useDispatch();
  const breakpoints = useBreakpoints();
  const theme = useTheme();
  const isMdUp = breakpoints.mdUp || false;
  const history = useHistory();
  const detailError = useSelector(state => state.detail?.error);
  const detailItem = useSelector(state => state.detail?.item);
  const detailUpdatePassError = useSelector(state => state.detail?.updatePassError);
  const detailUpdatePassSuccess = useSelector(state => state.detail?.updatePassSuccess);
  const detailWorking = useSelector(state => state.detail?.working);
  const foundationProfileUserId = useSelector(state => state.foundation?.profile?.userId);
  const relatedItems = useSelector(state => state.userProfile.relatedItems);
  const categoryTree = useSelector(state => state.categories?.categoryTree);
  const items = useSelector(state => state.categories?.items);
  const profile = settings.profile;
  const setProfile = settings.setProfile;
  const isMyProfile = profile.userId === foundationProfileUserId;
  const academicTitles = useAcademicTitlesState();
  const notifications = useSelector(state => state.notifications?.items);
  const adminParticipations = useSelector(state => state.communication?.adminParticipations);
  const adminParticipationsWorking = useSelector(state => state.communication?.adminParticipationsWorking);

  const adminParticipationListItems: IListItem[] = !!adminParticipations
    ? adminParticipations.map(part => {
        return {
          _id: part._id,
          title: part.content?.title ?? 'ERROR: no Title',
          typeId: part.type,
          type: part.type === ThingTypes.Group ? 'Arbeitsgruppe' : 'Projekt',
          __original: { ...part },
          avatar: {
            type: 'image',
            icon: faFlaskPotion,
            image: assetUrl(part.content?.avatar, true)
          },
          to: !!part.seo?.alias
            ? part.type === ThingTypes.Group
              ? `/coop/groups-projects/groups/${part.seo?.alias}?ref=${history.location.pathname}`
              : `/coop/groups-projects/projects/${part.seo?.alias}?ref=${history.location.pathname}`
            : undefined
        };
      })
    : [];
  const isSingleAdminAnywhere = adminParticipationListItems.length > 0;

  const memoryList = useSelector(state => state.foundation?.profile?.memoryList);
  const memoryIds = memoryList?.map(m => m.elementId);
  const booked = !!memoryIds && !!profile.profileId ? memoryIds.includes(profile.profileId) : false;


  const [setDepartment, setSetDepartment] = useState(false);
  const [editPrimaryPlayerActive, setEditPrimaryPlayerActive] = useState(false);
  const [userSelectedEmployerFirstLevel, setUserSelectedEmployerFirstLevel] = useState(null);
  const [userSelectedEmployerSecondLevel, setUserSelectedEmployerSecondLevel] = useState(null);
  const [userSelectedAdditionalEmployerFirstLevel, setUserSelectedAdditionalEmployerFirstLevel] = useState(null);
  const [userSelectedAdditionalEmployerSecondLevel, setUserSelectedAdditionalEmployerSecondLevel] = useState(null);
  const [changePwd, setChangePwd] = useState(false);
  const [isRemoval, setRemoval] = useState(false);
  const [pwdChange, setPwdChange] = useState<IPasswordChange & { newPasswordRepeat?: string }>({ oldPassword: '', newPassword: '', userId: profile.userId || '' });
  const [selectedTitle, setSelectedTitle] = useState<{ _id: string, title: string }>();
  const [titleQuery, setTitleQuery] = useState('');
  const [isRemovalOk, setRemovalOk] = useState(false);
  const [openHoverProfilePictureDialog, setOpenHoverProfilePictureDialog] = useState(false);
  const [profilePictureDialogOpened, setProfilePictureDialogOpened] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState<Array<INotification>>([]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    setEditPrimaryPlayerActive(false);
    setUserSelectedEmployerFirstLevel(null);
    setUserSelectedEmployerSecondLevel(null);
    setUserSelectedAdditionalEmployerFirstLevel(null);
    setUserSelectedAdditionalEmployerSecondLevel(null);
    if (!settings.isEditing) {
      setProfilePictureDialogOpened(false);
    }
  }, [settings.isEditing]);

  useEffect(() => {
    if (profile.title && !selectedTitle) {
      let profileTitle = academicTitles.find(aT => aT.title === profile.title);
      if (profileTitle) {
        setSelectedTitle(profileTitle)
      }
    }
  }, [profile.title, selectedTitle, academicTitles])

  useEffect(() => {
    !!profile.userId && dispatch(fetchRelatedItems({ userId: profile.userId }));
  }, [dispatch, profile.userId]);

  const [id, setId] = useState('');
  useEffect(() => {
    if (!isMyProfile && profile?.userId) {
      if (!id || profile?.userId !== id) {
        setId(profile?.userId);
        dispatch(userprofileCommunicationRequest({ id: profile?.userId }));
      }
    }
  }, [profile?.userId, id, dispatch, isMyProfile]);

  const handleChangePassword = () => {
    setChangePwd(true);
    settings.setEditMode(true);
    dispatch(userprofileUpdatePasswordReset());
  };

  const handleScroll = () => {
    if (isFunction(onScroll)) {
      if (document.documentElement.scrollTop < 100) {
        if (isScrolled) onScroll(false);
        return;
      }

      if (!isScrolled) onScroll(true);
    }
  }

  // Avatar
  const [avaUploading, setAvaUploading] = useState(false);
  const onDrop = React.useCallback(
    acceptedFiles => {
      setAvaUploading(true);
      acceptedFiles.forEach(file => {
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          setFiles(
            acceptedFiles.map(file =>
              Object.assign(file, {
                preview: URL.createObjectURL(file)
              })
            )
          );
          dispatch(userprofileUpdateAvatar(file));
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [dispatch]
  );

  const handleOnAvaRemove = () => {
    const file = new File([''], 'no-file');
    dispatch(userprofileUpdateAvatar(file));
    setFiles([]);
  };

  const [keyVisualUploading, setKeyVisualUploading] = useState(false);
  const [keyVisualFiles, setKeyVisualFiles] = React.useState<Array<{ preview: string } | null>>(
    profile.keyvisual ? [{ preview: assetUrl(profile.keyvisual, true) + `&s=background` }] : []
  );

  const onDropKeyVisual = React.useCallback(
    acceptedFiles => {
      setKeyVisualUploading(true);
      acceptedFiles.forEach(file => {
        const reader = new FileReader();
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          setKeyVisualFiles(
            acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }) )
          );
          dispatch(userprofileUpdateKeyVisual(file));
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [dispatch]
  );

  const handleOnKeyVisualRemove = () => {
    const file = new File([''], 'no-file');
    dispatch(userprofileUpdateKeyVisual(file));
    setKeyVisualFiles([]);
  };

  useEffect(() => {
    if (avaUploading) { setAvaUploading(false); }
    if (keyVisualUploading) { setKeyVisualUploading(false); }
  }, [profile, avaUploading, keyVisualUploading]);

  const toggleCategory = (isCompetence: boolean, guid: string) => {
    const toggleSet = new Set<string>((isCompetence ? profile?.competenceCategories : profile?.interestCategories)?.map(c => c.categoryId) ?? []);
    if (toggleSet.has(guid)) {
      toggleSet.delete(guid);
    } else {
      toggleSet.add(guid);
    }
    if (isCompetence) {
      setProfile({ ...profile, competenceCategories: Array.from(toggleSet).map<ICategory>(c => ({ categoryId: c })) });
    } else {
      setProfile({ ...profile, interestCategories: Array.from(toggleSet).map<ICategory>(c => ({ categoryId: c })) });
    }
  }
  const updateXing = (url: string) => {
    if (!profile.socialMedia) {
      setProfile({ ...profile, socialMedia: [{ type: PROFILE_TYPES.SOCIAL_MEDIA.XING, url }] });
    } else {
      const ofs = profile.socialMedia.findIndex(s => s.type === PROFILE_TYPES.SOCIAL_MEDIA.XING);
      const swap = Array.from(profile.socialMedia);
      if (ofs > -1) { swap.splice(ofs, 1); }
      swap.push({ type: PROFILE_TYPES.SOCIAL_MEDIA.XING, url });
      setProfile({ ...profile, socialMedia: [...swap] });
    }
  }
  const updateLinkedIn = (url: string) => {
    if (!profile.socialMedia) {
      setProfile({ ...profile, socialMedia: [{ type: PROFILE_TYPES.SOCIAL_MEDIA.LINKEDIN, url }] });
    } else {
      const ofs = profile.socialMedia.findIndex(s => s.type === PROFILE_TYPES.SOCIAL_MEDIA.LINKEDIN);
      const swap = Array.from(profile.socialMedia);
      if (ofs > -1) swap.splice(ofs, 1);
      swap.push({ type: PROFILE_TYPES.SOCIAL_MEDIA.LINKEDIN, url });
      setProfile({ ...profile, socialMedia: [...swap] });
    }
  }

  useEffect(() => {
    if (notifications && notifications.length) {
      setUnreadNotifications(notifications.filter(notification => !notification.content?.notificationRead))
    }
  }, [notifications]);

  const [files, setFiles] = React.useState<Array<{ preview: string } | null>>(profile.avatar ? [{ preview: assetUrl(profile.avatar, true) || '' }] : []);

  if (!!profile && !detailWorking) {
    if (detailError && !detailUpdatePassError) {
      alert('Ihre letzte Änderung konnte nicht gespeichert werden!');
      dispatch(userprofileRestore());
    }
    if (profile.avatar !== detailItem?.avatar) {
      setProfile({ ...profile, avatar: detailItem?.avatar });
    }
    if (profile.keyvisual !== detailItem?.keyvisual) {
      setProfile({ ...profile, keyvisual: detailItem?.keyvisual });
    }
  }

  const getPasswordInvalidMsg = () => {
    let error = false;
    if (pwdChange.newPassword.length < 8) { error = true; }
    if (!/[A-ZÄÖÜß]+/.test(pwdChange.newPassword)) { error = true; }
    if (!/[a-zäöüß]+/.test(pwdChange.newPassword)) { error = true; }
    if (!/[0-9]+/.test(pwdChange.newPassword)) { error = true; }
    return error
      ? 'Das Passwort muss Groß- und Kleinbuchstaben, eine Zahl und ein Sonderzeichen beinhalten und mindestens 8 Zeichen lang sein'
      : '';
  }

  const handleBgImageLoaded = () => {
    !bgImageLoaded && setBgImageLoaded(true);
  }
  const abbreviation = buildAbbreviation(profile?.fullName) ?? '';
  const hash = hashCode(abbreviation);
  const colorIndex = hash % 4;
  const colorObject = ListAvatarColors[colorIndex];

  const dispatcher = {
    shareClick: (detailItem) => {
      dispatch(setFlowDataAction({
        alias: 'share',
        flowData: {
          location: history.location,
          itemData: { sharedItem: detailItem }
        }
      }));
      history.push(history.location.pathname + '/flow' + history.location.search);
    }
  };

  const relatedItemsCreated = relatedItems?.created?.map(
    item => {
      return {
        ...item,
        badge: !!unreadNotifications?.find(notification => notification.content?.itemRef === item._id),
        booked: item.type === ThingTypes.News
          ? (!!item?.content?.url ? memoryIds?.includes(item?.content?.url) : false)
          : memoryIds?.includes(item._id)
      };
    }
  );

  const relatedItemsMember = relatedItems?.member?.map(
    item => {
      return {
        ...item,
        badge: !!unreadNotifications?.find(notification => notification.content?.itemRef === item._id),
        booked: item.type === ThingTypes.News
          ? (!!item?.content?.url ? memoryIds?.includes(item?.content?.url) : false)
          : memoryIds?.includes(item._id),
        noShare: item.type === ThingTypes.OneToOne || ((item.type === ThingTypes.Group || item.type === ThingTypes.Project) && !item.content?.listed)
      };
    }
  );

  const userValidationOpen = !!profile.generalUserStatus && profile.generalUserStatus === 'to_check_signature_employer';

  return (
    <>
      {!keyVisualUploading && (
        <Fade in={bgImageLoaded}>
          <div>
            <LayoutImage
              src={keyVisualFiles?.[0] ? keyVisualFiles[0].preview : TYPES_PROPS[TYPES.PROFILE]?.FallbackImage?.src}
              alt="Alt des Hintergrund-Bildes"
              position="absolute"
              top={0}
              left={0}
              right={0}
              width="100%"
              height={685}
              zIndex={-1}
              bgcolor={[
                { bgcolor: 'transparent', position: 0 },
                { bgcolor: 'background.default', position: 100 }
              ]}
              gradientDirection="to bottom"
              objectFit="cover"
              onLoadCallback={handleBgImageLoaded}
            />
          </div>
        </Fade>
      )}
      <FramedContent position="relative" zIndex={1}>
        <>
          {!!profile && !isSettings && !isAdmin && !isInvite && !isExpertRegistration && !settings.isEditing && (
            <>
              {!isMyProfile && profile?.userId && <OneToOneCommunicationPendingParticipationBanner userId={profile?.userId} />}
              <ProfileBox mt={isMdUp ? 14.5 : 4}>
                <Hidden smDown>
                  <Bx
                    position="absolute"
                    top={-(theme.custom?.ava?.largest?.height as number) / 2}
                    right={breakpoints.smDown ? avaRight.xs : avaRight.md}
                    boxShadow={1}
                    borderRadius="full"
                  >
                    {userValidationOpen &&
                      <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        color={'error'}
                        title={'User noch nicht validiert.'}
                        style={{fontSize:48}}
                        badgeContent={<FontAwesomeIcon icon={faHourglassClock} />}
                      >
                        <Ava src={assetUrl(profile.avatar || '', true)} style={{ color: colorObject.color, fontWeight: 500, backgroundColor: !profile?.avatar ? colorObject.bg : 'none' }} size="largest" variant="circular">{abbreviation}</Ava>
                      </Badge>
                    }
                    {!userValidationOpen &&
                      <Ava src={assetUrl(profile.avatar || '', true)} style={{ color: colorObject.color, fontWeight: 500, backgroundColor: !profile?.avatar ? colorObject.bg : 'none' }} size="largest" variant="circular">{abbreviation}</Ava>
                    }
                  </Bx>
                </Hidden>
                <Hidden mdUp>
                  <Bx
                    position="absolute"
                    top={-(theme.custom?.ava?.large?.height as number) / 2}
                    right={breakpoints.smDown ? avaRight.xs : avaRight.md}
                    boxShadow={1}
                    borderRadius="full"
                  >
                    {userValidationOpen &&
                      <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        color={'error'}
                        title={'User noch nicht validiert.'}
                        badgeContent={<FontAwesomeIcon icon={faHourglassClock} />}
                      >
                        <Ava src={assetUrl(profile.avatar || '', true)} style={{ color: colorObject.color, fontWeight: 500, backgroundColor: !profile?.avatar ? colorObject.bg : 'none' }} size="large" variant="circular">{abbreviation}</Ava>
                      </Badge>
                    }
                    {!userValidationOpen &&
                      <Ava src={assetUrl(profile.avatar || '', true)} style={{ color: colorObject.color, fontWeight: 500, backgroundColor: !profile?.avatar ? colorObject.bg : 'none' }} size="large" variant="circular">{abbreviation}</Ava>
                    }
                  </Bx>
                </Hidden>

                <Bx mb={5}>
                  <ProfileHead
                    type="Profil"
                    userName={(profile.fullName + '').trim()}
                    userFunction={profile.function}
                    isMdUp={isMdUp}
                  />
                </Bx>

                {!isMyProfile && (
                  <ProfileMetaBar
                    onRequest={id => settings.startInvitation?.(id)}
                    userId={profile?.userId}
                    share={() => {
                      dispatch(
                        setFlowDataAction({
                          alias: 'share',
                          flowData: {
                            location: history.location,
                            itemData: {
                              sharedItem: detailItem
                            }
                          }
                        })
                      );
                      history.push(history.location.pathname + '/flow' + history.location.search);
                    }}
                    booked={booked}
                    memoryClick={() => {dispatch(userprofileMemorylistRequestAction({
                      memoryItem: { elementId: profile.profileId || '', type: 'thing', typeId: TYPES.PROFILE },
                      type: booked ? 'DELETE' : 'POST',
                    }))}}
                  />
                )}

                {!!profile.player && profile.player.length > 0 && profile?.player[0]?.playerRootResolved && (
                  <Typo variant={isMdUp ? 'h6boosted' : 'h6'} gutterBottom>
                    <Link color="primary" to={'/info/experts-actors/actor/' + profile?.player[0]?.playerRootResolved?.alias + '?ref=' + history.location.pathname}>
                      {profile?.player?.[0]?.playerRootResolved?.name}
                    </Link>
                  </Typo>
                )}
                {/* Primary Company */}
                {!!profile.player && profile.player.length > 0 && (
                  <ExtendedListItem
                    avatar={
                      <IconAva size="medium" variant="circular">
                        <FontAwesomeIcon icon={GetActorIcon(profile?.player?.[0]?.playerResolved?.type)} />
                      </IconAva>
                    }
                  >
                    <Bx display="flex" justifyContent="center" flexDirection="column" height="100%">
                      <Link color="primary" to={'/info/experts-actors/actor/' + profile?.player[0]?.playerResolved?.alias + '?ref=' + history.location.pathname}>
                        <Typo fontWeight={500} variant="body1" component="span">
                          {profile?.player?.[0]?.playerResolved?.name}
                        </Typo>
                      </Link>
                    </Bx>
                    <Bx display="flex" justifyContent="center" flexDirection="column" height="100%">
                      <Typo variant="body2" component="span">
                        {profile?.player?.[0]?.playerResolved?.city}
                      </Typo>
                    </Bx>
                  </ExtendedListItem>
                )}

                {/* Communitcation */}
                {[PROFILE_TYPES.COMMUNICATION_CHANNELS.PHONE, PROFILE_TYPES.COMMUNICATION_CHANNELS.MOBILE].map((t, i) => {
                  return (
                    ((!!profile.communicationChannels && profile.communicationChannels.some(c => c.type === t)) || settings.isEditing) && (
                      <Bx key={'phone_' + i} pt={1.5} display="flex" alignItems="center">
                        <Bx width={36}>
                          <Typo variant={isMdUp ? 'h6boosted' : 'h6'}>
                            <FontAwesomeIcon icon={t === PROFILE_TYPES.COMMUNICATION_CHANNELS.PHONE ? faPhoneOffice : faMobile} />
                          </Typo>
                        </Bx>
                        <Bx>
                          <Typo variant={isMdUp ? 'h5boosted' : 'h5'}>
                            <LinkBehavior
                              underline="hover"
                              component="a"
                              href={
                                'tel:' +
                                (profile.communicationChannels || [])
                                  .find(c => c.type === t)
                                  ?.identifier.replace(/ /g, '')
                                  .replace(/\//g, '')
                                  .replace(/-/g, '')
                              }
                            >
                              {(profile.communicationChannels || []).find(c => c.type === t)?.identifier || ''}
                            </LinkBehavior>
                          </Typo>
                        </Bx>
                      </Bx>
                    )
                  );
                })}
                {(!!profile.website || settings.isEditing) && (
                  <Bx pt={1.5} display="flex" alignItems="center">
                    <Bx width={36}>
                      <Typo variant={isMdUp ? 'h6boosted' : 'h6'}>
                        <FontAwesomeIcon icon={faGlobe} />
                      </Typo>
                    </Bx>
                    <Bx>
                      <Typo variant={isMdUp ? 'h5boosted' : 'h5'}>
                        <LinkBehavior underline="hover" component="a" href={`https://${profile.website?.replace('http://', '').replace('https://', '')}`} target="_blank">
                          {profile.website?.replace('http://', '').replace('https://', '')}
                        </LinkBehavior>
                      </Typo>
                    </Bx>
                  </Bx>
                )}

                {/* Social-Media */}
                {(!!profile.socialMedia?.find(s => s.type === PROFILE_TYPES.SOCIAL_MEDIA.XING)?.url ||
                  !!profile.socialMedia?.find(s => s.type === PROFILE_TYPES.SOCIAL_MEDIA.LINKEDIN)?.url) && (
                  <Bx mt={4}>
                    <BtnList spacing={1}>
                      {!!profile.socialMedia?.find(s => s.type === PROFILE_TYPES.SOCIAL_MEDIA.XING)?.url && (
                        <IconBtn
                          variant="outlined"
                          title="zum XING Profil"
                          target="_blank"
                          to={profile.socialMedia?.find(s => s.type === PROFILE_TYPES.SOCIAL_MEDIA.XING)?.url}
                        >
                          <FontAwesomeIcon icon={faXing} />
                        </IconBtn>
                      )}
                      {!!profile.socialMedia?.find(s => s.type === PROFILE_TYPES.SOCIAL_MEDIA.LINKEDIN)?.url && (
                        <IconBtn
                          variant="outlined"
                          title="zum LinkedIn Profil"
                          target="_blank"
                          to={profile.socialMedia?.find(s => s.type === PROFILE_TYPES.SOCIAL_MEDIA.LINKEDIN)?.url}
                        >
                          <FontAwesomeIcon icon={faLinkedinIn} />
                        </IconBtn>
                      )}
                    </BtnList>
                  </Bx>
                )}

                {/* Competences & Interests */}
                {!!categoryTree &&
                  !!items &&
                  !!profile &&
                  ((!!profile.competenceCategories && profile.competenceCategories.length > 0) || (!!profile.interestCategories && profile.interestCategories.length > 0)) && (
                    <Bx mt={10}>
                      {!!profile && !!profile.competenceCategories && profile.competenceCategories.length > 0 && (
                        <Bx mb={6}>
                          <Bx mb={2}>
                            <Typo variant={isMdUp ? 'h3boosted' : 'h3'} component="h3">
                              Kompetenzen
                            </Typo>
                          </Bx>
                          <BtnList>
                            {profile?.competenceCategories?.map((c, i) =>
                              items[c.categoryId] ? <Chip key={i} variant="outlined" size="medium" label={items[c.categoryId].name} /> : <React.Fragment key={i} />
                            )}
                          </BtnList>
                        </Bx>
                      )}
                      {!!profile && !!profile.interestCategories && profile.interestCategories.length > 0 && (
                        <>
                          <Bx mb={2}>
                            <Typo variant={isMdUp ? 'h3boosted' : 'h3'} component="h3">
                              Interessen
                            </Typo>
                          </Bx>
                          <BtnList>
                            {profile?.interestCategories?.map((i, o) =>
                              items[i.categoryId] ? <Chip key={o} variant="outlined" size="medium" label={items[i.categoryId].name} /> : <React.Fragment key={o} />
                            )}
                          </BtnList>
                        </>
                      )}
                    </Bx>
                  )}
              </ProfileBox>

              {/* Weitere Arbeitgeber */}
              {!!profile && !!profile.player && profile.player.length > 1 && (
                <ProfileBox>
                  <>
                    <HeadlineContainer mb={2} headline="Weitere Arbeitgeber" headlineVariant={isMdUp ? 'h3boosted' : 'h3'} hideUnderline />
                    <ListWrapper divider="both">
                      {profile.player.slice(1).map((p, i) => {
                        return (
                          <ExtendedListItem
                            key={i}
                            to={'/info/experts-actors/actor/' + p.playerResolved?.alias}
                            avatar={
                              <IconAva size="large" variant="rounded">
                                <FontAwesomeIcon icon={GetActorIcon(p.playerResolved?.type)} />
                              </IconAva>
                            }
                          >
                            <Bx display="flex" justifyContent="center" flexDirection="column" height="100%" color="text.primary">
                              {p.playerResolved?.name && (
                                <Typo fontWeight={500} variant="body1" component="span">
                                  {p.playerRootResolved?.name ? `${p.playerRootResolved?.name} - ` : ''}
                                  {p.playerResolved?.name}
                                </Typo>
                              )}
                              {p.workFunction && (
                                <Typo variant="body2" component="span">
                                  {p.workFunction}
                                </Typo>
                              )}
                            </Bx>
                          </ExtendedListItem>
                        );
                      })}
                    </ListWrapper>
                  </>
                </ProfileBox>
              )}
              {relatedItems && (
                <ProfileBox>
                  {!relatedItems.created ? (
                    <Skeleton animation="wave" />
                  ) : (
                    <ListWidget
                      backgroundWorking={false}
                      working={false}
                      ignoreWorking={true}
                      items={relatedItemsCreated}
                      headline={isMyProfile ? 'Meine Beiträge' : 'Beiträge von ' + profile.firstName + ' ' + profile.lastName}
                      showAllClick={undefined}
                      emptyCaption="keine Beiträge verfügbar"
                      shareClick={dispatcher.shareClick}
                      itemMemoClicked={(item, type) => dispatch(userprofileMemorylistRequestAction({ memoryItem: item, type }))}
                    />
                  )}
                </ProfileBox>
              )}
              {relatedItems && (
                <ProfileBox>
                  {!relatedItems.member ? (
                    <Skeleton animation="wave" />
                  ) : (
                    <ListWidget
                      backgroundWorking={false}
                      working={false}
                      ignoreWorking={true}
                      items={relatedItemsMember}
                      headline={isMyProfile ? 'Meine Aktivitäten' : 'Aktivitäten von ' + profile.firstName + ' ' + profile.lastName}
                      showAllClick={undefined}
                      emptyCaption="keine Aktivitäten verfügbar"
                      shareClick={dispatcher.shareClick}
                      itemMemoClicked={(item, type) => dispatch(userprofileMemorylistRequestAction({ memoryItem: item, type }))}
                    />
                  )}
                </ProfileBox>
              )}
            </>
          )}

          {/* Regular View -> Settings */}
          {!!profile && isMyProfile && isSettings && !isAdmin && !isInvite && !isExpertRegistration && !settings.isEditing && (
            <>
              <ProfileBox>
                {!changePwd && !isRemoval && (
                  <>
                    <Bx mb={6}>
                      <HeadlineContainer
                        mb={2}
                        headline="Sichtbarkeit Profildaten"
                        headlineVariant={isMdUp ? 'h3boosted' : 'h3'}
                        endAdornment={undefined}
                      />
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Legen Sie die Sichtbarkeit Ihrer Kontaktdaten (Telefon, Mobil, LinkedIn etc.) fest:</Typo>
                      <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" mt={2}>
                        <Grid item xs={5}>
                          <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Für alle sichtbar</Typo>
                        </Grid>
                        <Grid item xs={1}>
                          <SwitchControl
                            color={'secondary'}
                            checked={profile.dataVisibility?.all}
                            disabled={false}
                            onChange={() => settings.changeDataVisibility('all')}
                          />
                        </Grid>
                      </Bx>
                      <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" mt={2}>
                        <Grid item xs={5}>
                          <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Chat</Typo>
                        </Grid>
                        <Grid item xs={1}>
                          <SwitchControl
                            color={'secondary'}
                            checked={profile.dataVisibility?.chats}
                            disabled={false}
                            onChange={() => settings.changeDataVisibility('chats')}
                          />
                        </Grid>
                      </Bx>
                      <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" mt={2}>
                        <Grid item xs={5}>
                          <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Geschlossene Gruppen</Typo>
                        </Grid>
                        <Grid item xs={1}>
                          <SwitchControl
                            color={'secondary'}
                            checked={profile.dataVisibility?.closedGroups}
                            disabled={false}
                            onChange={() => settings.changeDataVisibility('closedGroups')}
                          />
                        </Grid>
                      </Bx>
                      <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" mt={2}>
                        <Grid item xs={5}>
                          <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Offene Gruppen</Typo>
                        </Grid>
                        <Grid item xs={1}>
                          <SwitchControl
                            color={'secondary'}
                            checked={profile.dataVisibility?.openGroups}
                            disabled={false}
                            onChange={() => settings.changeDataVisibility('openGroups')}
                          />
                        </Grid>
                      </Bx>
                    </Bx><Bx mb={6}>
                      <HeadlineContainer
                        mb={2}
                        headline="Mail-Benachrichtigungen"
                        headlineVariant={isMdUp ? 'h3boosted' : 'h3'}
                        endAdornment={undefined}
                      />
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Legen Sie die Bereiche von MTO fest, aus denen Sie <b>keine</b> Mail-Benachrichtigungen erhalten wollen:</Typo>
                      <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" mt={2}>
                        <Grid item xs={5}>
                          <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Keine Mails</Typo>
                        </Grid>
                        <Grid item xs={1}>
                          <SwitchControl
                            color={'secondary'}
                            checked={profile.messagingPermissions?.all}
                            disabled={false}
                            onChange={() => settings.changeMessagingPermissions('all')}
                          />
                        </Grid>
                      </Bx>
                      <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" mt={2}>
                        <Grid item xs={5}>
                          <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Chat</Typo>
                        </Grid>
                        <Grid item xs={1}>
                          <SwitchControl
                            color={'secondary'}
                            checked={profile.messagingPermissions?.chat}
                            disabled={false}
                            onChange={() => settings.changeMessagingPermissions('chat')}
                          />
                        </Grid>
                      </Bx>
                      <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" mt={2}>
                        <Grid item xs={5}>
                          <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Gruppen-Chat</Typo>
                        </Grid>
                        <Grid item xs={1}>
                          <SwitchControl
                            color={'secondary'}
                            checked={profile.messagingPermissions?.groupChat}
                            disabled={false}
                            onChange={() => settings.changeMessagingPermissions('groupChat')}
                          />
                        </Grid>
                      </Bx>
                      <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" mt={2}>
                        <Grid item xs={5}>
                          <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Gruppen-Meeting</Typo>
                        </Grid>
                        <Grid item xs={1}>
                          <SwitchControl
                            color={'secondary'}
                            checked={profile.messagingPermissions?.groupMeeting}
                            disabled={false}
                            onChange={() => settings.changeMessagingPermissions('groupMeeting')}
                          />
                        </Grid>
                      </Bx>
                      <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" mt={2}>
                        <Grid item xs={5}>
                          <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Forum</Typo>
                        </Grid>
                        <Grid item xs={1}>
                          <SwitchControl
                            color={'secondary'}
                            checked={profile.messagingPermissions?.forum}
                            disabled={false}
                            onChange={() => settings.changeMessagingPermissions('forum')}
                          />
                        </Grid>
                      </Bx>
                      <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" mt={2}>
                        <Grid item xs={5}>
                          <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Magazin</Typo>
                        </Grid>
                        <Grid item xs={1}>
                          <SwitchControl
                            color={'secondary'}
                            checked={profile.messagingPermissions?.magazine}
                            disabled={false}
                            onChange={() => settings.changeMessagingPermissions('magazine')}
                          />
                        </Grid>
                      </Bx>
                    </Bx>
                    <Bx mb={6}>
                      <HeadlineContainer
                        mb={2}
                        headline="Chat-Anfragen erlauben"
                        headlineVariant={isMdUp ? 'h3boosted' : 'h3'}
                        endAdornment={
                          <Bx ml={1}>
                            <FormControlLabel
                              label={breakpoints.xsDown ? '' : (profile.chatAllowed ? 'Chat-Anfragen aktiviert' : 'Chat-Anfragen deaktiviert')}
                              labelPlacement="start"
                              control={
                                <SwitchControl
                                  color={'secondary'}
                                  checked={profile.chatAllowed}
                                  disabled={false}
                                  onChange={settings.openChatModal}
                                />
                              }
                            />
                          </Bx>
                        }
                      />
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Hier können Sie Chat-Anfragen anderer Teilnehmer deaktivieren oder wieder aktivieren. Dies betrifft nur die direkte Kommunikation.</Typo>
                    </Bx>
                    <Bx mb={6}>
                      <HeadlineContainer
                        mb={2}
                        headline="Externe Links erlauben"
                        headlineVariant={isMdUp ? 'h3boosted' : 'h3'}
                        endAdornment={
                          <Bx ml={1}>
                            <FormControlLabel
                              label={breakpoints.xsDown ? '' : (profile.externalLinksAllowed ? 'externe Links aktiviert' : 'externe Links deaktiviert')}
                              labelPlacement="start"
                              control={
                                <SwitchControl
                                  color={'secondary'}
                                  checked={profile.externalLinksAllowed}
                                  disabled={false}
                                  onChange={settings.openExternalLinkModal}
                                />
                              }
                            />
                          </Bx>
                        }
                      />
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Hier können Sie den Warnhinweis für externe Quellen (de)aktivieren.</Typo>
                    </Bx>
                    
                    <Bx mb={6}>
                      <HeadlineContainer
                        mb={2}
                        headline="Start-Hinweis ein/ausblenden"
                        headlineVariant={isMdUp ? 'h3boosted' : 'h3'}
                        endAdornment={
                          <Bx ml={1}>
                            <FormControlLabel
                              label={breakpoints.xsDown ? '' : (profile?.alertRead ? 'Start-Hinweis gelesen' : 'Start-Hinweis nicht gelesen')}
                              labelPlacement="start"
                              control={
                                <SwitchControl
                                  color={'secondary'}
                                  checked={profile?.alertRead}
                                  disabled={false}
                                  onChange={(event, checked) => {
                                    dispatch(userprofileAlertReadRequestAction({alertRead: checked}));
                                    setProfile({ ...profile, alertRead: checked });
                                  }}
                                />
                              }
                            />
                          </Bx>
                        }
                      />
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Hier können Sie den Hinweis auf der Startseite (Aktivitäten) (de)aktivieren.</Typo>
                    </Bx>
                    <Bx mb={6}>
                      <HeadlineContainer
                        mb={2}
                        headline="Passwort ändern"
                        headlineVariant={isMdUp ? 'h3boosted' : 'h3'}
                        endAdornment={
                          <Bx ml={1}>
                            <Hidden smDown>
                              <Btn size="large" startIcon={<FontAwesomeIcon icon={faKey} />} variant="outlined" onClick={handleChangePassword}>
                                Passwort ändern
                              </Btn>
                            </Hidden>
                            <Hidden mdUp>
                              <IconBtn size="small" shape="rounded" variant="outlined" onClick={handleChangePassword}>
                                <FontAwesomeIcon icon={faKey} />
                              </IconBtn>
                            </Hidden>
                          </Bx>
                        }
                      />
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Hier können Sie Ihr Passwort für den Zugang zu den geschützten Bereichen des Portals ändern.</Typo>
                    </Bx>
                    <Bx mb={6}>
                      <HeadlineContainer
                        mb={2}
                        headline="DSGVO Datenexport"
                        headlineVariant={isMdUp ? 'h3boosted' : 'h3'}
                        endAdornment={
                          <Bx ml={1}>
                            <Hidden smDown>
                              <Btn size="large" startIcon={<FontAwesomeIcon icon={faFileExport} />} variant="outlined" onClick={() => window.open(api.v1.profile.dsgvoExport)}>
                                Daten exportieren
                              </Btn>
                            </Hidden>
                            <Hidden mdUp>
                              <IconBtn size="small" shape="rounded" variant="outlined" onClick={() => window.open(api.v1.profile.dsgvoExport)}>
                                <FontAwesomeIcon icon={faFileExport} />
                              </IconBtn>
                            </Hidden>
                          </Bx>
                        }
                      />
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>
                        Hier können Sie ihre gemäß unserer aktuellen <Link to={'/content/page/datenschutz?ref=' + history.location.pathname}>Datenschutzerklärung</Link>{' '}
                        erhobenen personenbezogenen Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung unseres Vertrags mit Ihnen gemäß den aktuellen{' '}
                        <Link to={'/content/page/nutzungsbedingungen?ref=' + history.location.pathname}>Nutzungsbedingungen</Link> automatisiert verarbeiten, in einem
                        gängigen, maschinenlesbaren Format exportieren.
                      </Typo>
                    </Bx>
                    <Bx mb={6}>
                      <HeadlineContainer
                        mb={2}
                        headline="Cookie Consents verwalten"
                        headlineVariant={isMdUp ? 'h3boosted' : 'h3'}
                        endAdornment={
                          <Bx ml={1}>
                            <Hidden smDown>
                              <Btn size="large" startIcon={<FontAwesomeIcon icon={faCookie} />} variant="outlined" onClick={() => window['klaro']?.show?.()}>
                                Cookie Consents verwalten
                              </Btn>
                            </Hidden>
                            <Hidden mdUp>
                              <IconBtn size="small" shape="rounded" variant="outlined" onClick={() => window['klaro']?.show?.()}>
                                <FontAwesomeIcon icon={faCookie} />
                              </IconBtn>
                            </Hidden>
                          </Bx>
                        }
                      />
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Hier können Sie einsehen und anpassen, welche Information wir über Sie sammeln.</Typo>
                    </Bx>
                    <Bx mb={6}>
                      <HeadlineContainer
                        mb={2}
                        headline="Cookie Consents zurücksetzen"
                        headlineVariant={isMdUp ? 'h3boosted' : 'h3'}
                        endAdornment={
                          <Bx ml={1}>
                            <Hidden smDown>
                              <Btn
                                size="large"
                                startIcon={<FontAwesomeIcon icon={faCookieBite} />}
                                variant="outlined"
                                onClick={() => {
                                  window['klaro']?.getManager?.().resetConsents?.();
                                  window.location.reload();
                                }}
                              >
                                Cookie Consents zurücksetzen
                              </Btn>
                            </Hidden>
                            <Hidden mdUp>
                              <IconBtn
                                size="small"
                                shape="rounded"
                                variant="outlined"
                                onClick={() => {
                                  window['klaro']?.getManager?.().resetConsents?.();
                                  window.location.reload();
                                }}
                              >
                                <FontAwesomeIcon icon={faCookieBite} />
                              </IconBtn>
                            </Hidden>
                          </Bx>
                        }
                      />
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>Hier können Sie Ihre Cookie-Einstellungen zurücksetzen.</Typo>
                    </Bx>
                    <Bx>
                      <HeadlineContainer
                        mb={2}
                        headline="Nutzerkonto löschen"
                        headlineVariant={isMdUp ? 'h3boosted' : 'h3'}
                        endAdornment={
                          <Bx ml={1}>
                            <Hidden smDown>
                              <Btn size="large"
                                color="quaternary"
                                startIcon={<FontAwesomeIcon icon={faKey} />}
                                variant="outlined"
                                onClick={() => {
                                  setRemoval(true);
                                  dispatch(fetchAdminParticipationsRequest({}));
                                }}
                              >
                                Konto löschen
                              </Btn>
                            </Hidden>
                            <Hidden mdUp>
                              <IconBtn size="small"
                                shape="rounded"
                                color="quaternary"
                                variant="outlined"
                                onClick={() => {
                                  setRemoval(true);
                                  dispatch(fetchAdminParticipationsRequest({}));
                                }}
                              >
                                <FontAwesomeIcon icon={faKey} />
                              </IconBtn>
                            </Hidden>
                          </Bx>
                        }
                      />
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>
                        Hier können Sie Ihr Nutzerkonto schließen und löschen damit auch Ihr Nutzerprofil. Bezüglich der Löschung Ihrer Daten auf unserer Webseite beachten Sie
                        bitte die Regelungen in unserer aktuellen <Link to={'/content/page/datenschutz?ref=' + history.location.pathname}>Datenschutzerklärung</Link>.
                      </Typo>
                    </Bx>
                  </>
                )}

                {isRemoval && (
                  <Bx my={2}>
                    <HeadlineContainer mb={2} headline="Nutzerkonto löschen" headlineVariant={isMdUp ? 'h3boosted' : 'h3'} />

                    <Bx mb={6}>
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'} gutterBottom>
                        Sie sind dabei, Ihr Nutzerkonto zu schließen und löschen damit auch Ihr Nutzerprofil. Dieser Vorgang ist irreversibel! Bezüglich der Löschung Ihrer
                        Daten auf unserer Webseite beachten Sie bitte die Regelungen in unserer aktuellen{' '}
                        <Link to={'/content/page/datenschutz?ref=' + history.location.pathname}>Datenschutzerklärung</Link>.Bitte beachten Sie insbesondere, dass von Ihnen
                        veröffentlichte Fachbeiträge in unseren Diensten aus urheberschutzrechtlichen Gründen mit Ihrem Namen verknüpft bleiben. Ihr Name wird aber nicht mehr
                        mit einem Profil verknüpft. Diese Verknüpfung lässt sich für den Fall, dass Sie sich erneut registrieren, nicht wieder herstellen.
                      </Typo>
                      <br />
                      {isSingleAdminAnywhere && <>
                        <ListWidget
                          backgroundWorking={false}
                          working={adminParticipationsWorking}
                          ignoreWorking={true}
                          items={adminParticipationListItems}
                          headline={'Bitte benennen Sie einen neuen Administrator für Ihre Gruppen. Wenn Sie keinen Nachfolge-Administrator für Ihre Gruppen benenennen, erfolgt eine Zuweisung systemseitig.'}
                          showAllClick={undefined}
                          emptyCaption="Kein Admin"
                          listType="adminParticipation"
                        />
                      </>}
                      <br />
                      <br />
                      <Typo variant={isMdUp ? 'body1boosted' : 'body1'}>
                        Bitte geben Sie das Wort "<strong>{killPhrase}</strong>", ohne die Anführungszeichen, in nachfolgendes Textfeld ein um zu bestätigen, dass Ihr Konto
                        gelöscht werden soll.
                      </Typo>
                    </Bx>

                    <Bx mb={6}>
                      <FormInput
                        label="Lösch-Bestätigung"
                        maxLength={killPhrase.length}
                        minLength={killPhrase.length}
                        placeholder=""
                        onChange={val => setRemovalOk(val === killPhrase)}
                        value=""
                        validator={val => val === killPhrase}
                      />
                    </Bx>

                    <Bx display="flex" justifyContent="space-between">
                      <Btn size="large" startIcon={<FontAwesomeIcon icon={faTimes} />} variant="outlined" color="default" onClick={val => setRemoval(false)}>
                        Abbrechen
                      </Btn>
                      <Btn
                        disabled={!isRemovalOk}
                        size="large"
                        startIcon={<FontAwesomeIcon icon={faTrash} />}
                        variant="contained"
                        color="quaternary"
                        onClick={() => {
                          dispatch(userprofileTermination());
                          setRemoval(false);
                          history.push('/logout');
                        }}
                      >
                        Benutzerkonto endgültig löschen
                      </Btn>
                    </Bx>
                  </Bx>
                )}
              </ProfileBox>
            </>
          )}

          {/* Edit View -> Profile */}
          {!!profile && !isSettings && !isAdmin && !isInvite && !isExpertRegistration && settings.isEditing && (
            <>
              <ProfileBox mt={isMdUp ? 14.5 : 4}>
                <Bx mb={isMdUp ? 5 : 2}>
                  <ProfileHead type="Profil bearbeiten" userName={(profile.fullName + '').trim()} userFunction={profile.function} isMdUp={isMdUp} />
                </Bx>

                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Bx mb={1}><Typo component='span' variant='body2' fontWeight={500}>Akademischer Titel</Typo></Bx>
                    <Autocomplete
                      value={selectedTitle}
                      options={academicTitles}
                      noOptionsText={!!academicTitles && academicTitles.length === 0 ? 'keine Ergebnisse' : 'Lade...'}
                      autoComplete={false}
                      inputValue={titleQuery}
                      includeInputInList={false}
                      placeholder="keine Angabe"
                      renderInput={params => <TextField {...params} label="" variant="outlined" fullWidth />}
                      onChange={(e, v: any) => {
                        setSelectedTitle(v);
                        setProfile({ ...profile, title: v?.title.trim(), fullName: (v?.title ? v?.title.trim() + ' ' : '') + profile.firstName + ' ' + profile.lastName})
                      }}
                      onInputChange={(event, newInputValue) => {
                        setTitleQuery(newInputValue);
                      }}
                      getOptionLabel={o => o.title}
                      getOptionSelected={o => o._id === selectedTitle?._id}
                      renderOption={o => {
                        return (
                          <Bx display="flex" alignItems="stretch">
                            <Bx mr={2}>
                              <Typo variant="subtitle1" component="span">
                                {o.title}
                              </Typo>
                            </Bx>
                          </Bx>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Hidden smDown>
                      <Bx
                        position="absolute"
                        top={-(theme.custom?.ava?.largest?.height as number) / 2}
                        right={breakpoints.smDown ? avaRight.xs : avaRight.md}
                        boxShadow={1}
                        borderRadius="full"
                        onMouseEnter={() => {if(!profilePictureDialogOpened){setOpenHoverProfilePictureDialog(true)}}}
                        onDragEnter={() => {if(!profilePictureDialogOpened){setOpenHoverProfilePictureDialog(true)}}}
                      >
                        <AvaDropZone
                          label={'Hochladen oder Profilbild hier ablegen'}
                          acceptLabel={'Dateiformat akzeptiert. Bitte ablegen.'}
                          rejectLabel={'Dateiformat wird nicht akzeptiert.'}
                          loading={avaUploading && files.length > 0 && detailWorking}
                          onDrop={onDrop}
                          removeOnClick={handleOnAvaRemove}
                          image={files.length > 0 ? files?.[0]?.preview : undefined}
                          imageAlt={files.length > 0 ? `Profilbild von ${(profile.fullName + '').trim()}` : undefined}
                          accept={['image/*']}
                          maxSize={Infinity}
                          minSize={50}
                        />
                      </Bx>
                    </Hidden>
                    <Hidden mdUp>
                      <Bx mb={8}>
                        <Input
                          inputLabel={{ children: 'Profilbild' }}
                          inputComponent={
                            <AvaDropZone
                              loading={avaUploading && files.length > 0 && detailWorking}
                              onDrop={onDrop}
                              removeOnClick={handleOnAvaRemove}
                              image={files.length > 0 ? files?.[0]?.preview : undefined}
                              imageAlt={files.length > 0 ? `Profilbild von ${(profile.fullName + '').trim()}` : undefined}
                              accept={['image/*']}
                              maxSize={Infinity}
                              minSize={50}
                            />
                          }
                        />
                      </Bx>
                    </Hidden>
                    <HoverProfilePictureDialog
                      open={openHoverProfilePictureDialog}
                      headerText={'Verwendung Profilbild auf MTO'}
                      noteToUserText={'Das von Ihnen hinterlegte Profilbild wird an Ihren Beiträgen sowie in den E-Mail-Benachrichtigungen zu Ihren auf Medtec Online veröffentlichten Nachrichten und Beiträgen ausgespielt. Falls Sie kein persönliches Profilbild hinterlegen, verwenden die Medtec Online-Dienste ein Bild der Initialen Ihres Vor- und Nachnamens. Sie können Ihr Profilbild jederzeit wieder entfernen.'}
                      labelText={'OK'}
                      onCanceled={() => {setOpenHoverProfilePictureDialog(false);setProfilePictureDialogOpened(true);}}
                      // onMouseEnter={() => {if (profilePictureDialogTimeoutId) { clearTimeout(profilePictureDialogTimeoutId);}}}
                      // onMouseLeave={() => {const timerId = setTimeout(() => {
                      //   setOpenHoverProfilePictureDialog(false);
                      //   setProfilePictureDialogOpened(true);
                      // }, 5000);
                      // setProfilePictureDialogTimeoutId(timerId);}}
                    />
                    <Bx mb={{ xs: 8, md: 0 }}>
                      <Input
                        inputLabel={{ children: 'Profil Hintergrundbild' }}
                        inputComponent={
                          <Bx display="flex" alignItems="center">
                            <Bx flexGrow={1} flexShrink={1}>
                              <DropZone style={{ height: 56 }} accept={['image/*']} maxFiles={1} maxSize={3000000} multiple={false} minSize={1} onDrop={onDropKeyVisual} />
                            </Bx>
                            {!keyVisualUploading && keyVisualFiles.length > 0 && (
                              <Bx ml={1}>
                                <IconBtn size="medium" variant="text" color="default" onClick={handleOnKeyVisualRemove}>
                                  <FontAwesomeIcon icon={faTrash} />
                                </IconBtn>
                              </Bx>
                            )}
                            {keyVisualUploading && detailWorking && keyVisualFiles.length > 0 && (
                              <Bx ml={1} width={48} height="100%" display="flex" alignItems="center" justifyContent="center">
                                <CircularProgress />
                              </Bx>
                            )}
                          </Bx>
                        }
                      />
                    </Bx>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormInput
                      label="Vorname"
                      minLength={2}
                      maxLength={100}
                      placeholder="Vorname eingeben"
                      value={profile.firstName || ''}
                      onChange={val => setProfile({ ...profile, firstName: val.trim(), fullName: (profile.title ? profile.title.trim() + ' ' : '') + val.trim() + ' ' + profile.lastName })}
                      successMessage=""
                      lazy={true}
                      validator
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormInput
                      label="Nachname"
                      minLength={2}
                      maxLength={100}
                      placeholder="Nachname eingeben"
                      value={profile.lastName || ''}
                      onChange={val => setProfile({ ...profile, lastName: val.trim(), fullName: (profile.title ? profile.title.trim() + ' ' : '') + profile.firstName + ' ' + val.trim() })}
                      successMessage=""
                      lazy={true}
                      validator
                    />
                  </Grid>
                  {[PROFILE_TYPES.COMMUNICATION_CHANNELS.PHONE, PROFILE_TYPES.COMMUNICATION_CHANNELS.MOBILE].map((t, i) => {
                    return (
                      ((!!profile.communicationChannels && profile.communicationChannels.some(c => c.type === t)) || settings.isEditing) && (
                        <Grid key={'phone_' + i} item xs={12} md={6}>
                          <FormInput
                            // startIcon={t === PROFILE_TYPES.COMMUNICATION_CHANNELS.PHONE ? faPhoneOffice : faMobile}
                            label={t === PROFILE_TYPES.COMMUNICATION_CHANNELS.PHONE ? 'Telefon' : 'Mobil'}
                            minLength={0}
                            maxLength={100}
                            type="text"
                            placeholder="keine Angabe"
                            value={(profile.communicationChannels || []).find(c => c.type === t)?.identifier || ''}
                            onChange={val => {
                              const swap = profile.communicationChannels || [];

                              setProfile({
                                ...profile,
                                communicationChannels: [...swap.filter(c => c.type !== t), { type: t, identifier: val }]
                              });
                            }}
                            validator={validatePhone}
                            successMessage=""
                            invalidMessage="Bitte geben Sie eine gültige Telefonnummer ein"
                            lazy={true}
                          />
                        </Grid>
                      )
                    );
                  })}
                  <Grid item xs={12} md={6}>
                    <Input inputLabel={{ children: 'E-Mail', mandatory: true }} input={{ value: profile.email, readOnly: true, fullWidth: true, disabled: true }} />
                    {/* <FormInput
                        // startIcon={faGlobe}
                        label="Website"
                        minLength={0}
                        maxLength={200}
                        placeholder="keine Angabe"
                        value={profile.email || ''}
                        onChange={(val) => setProfile({ ...profile, email: val.trim() })}
                        validator={validateEmail}
                        successMessage=""
                        invalidMessage="Es muss sich um eine gültige E-Mail Adresse handeln!"
                        lazy={true}
                      /> */}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {(!!profile.website || settings.isEditing) && (
                      <FormInput
                        // startIcon={faGlobe}
                        label="Website"
                        minLength={0}
                        maxLength={200}
                        placeholder="keine Angabe"
                        value={profile.website || ''}
                        onChange={val => setProfile({ ...profile, website: val.trim() })}
                        validator={validateUrl}
                        successMessage=""
                        invalidMessage="Die URL muss valide sein und mit http://, oder https:// beginnen!"
                        lazy={true}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    {(!!profile.socialMedia?.find(s => s.type === PROFILE_TYPES.SOCIAL_MEDIA.XING)?.url || settings.isEditing) && (
                      <FormInput
                        // startIcon={faXing}
                        label="XING"
                        minLength={0}
                        maxLength={200}
                        placeholder="keine Angabe"
                        value={profile.socialMedia?.find(s => s.type === PROFILE_TYPES.SOCIAL_MEDIA.XING)?.url || ''}
                        onChange={val => updateXing(val.trim())}
                        validator={validateXing}
                        successMessage=""
                        invalidMessage="Die URL muss mit https://www.xing.com/profile beginnen!"
                        lazy={true}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {(!!profile.socialMedia?.find(s => s.type === PROFILE_TYPES.SOCIAL_MEDIA.LINKEDIN)?.url || settings.isEditing) && (
                      <FormInput
                        // startIcon={faLinkedinIn}
                        label="LinkedIn"
                        minLength={0}
                        maxLength={200}
                        placeholder="keine Angabe"
                        value={profile.socialMedia?.find(s => s.type === PROFILE_TYPES.SOCIAL_MEDIA.LINKEDIN)?.url || ''}
                        onChange={val => updateLinkedIn(val.trim())}
                        validator={validateLinkedIn}
                        successMessage=""
                        invalidMessage="Die URL muss mit https://www.linkedin.com/in beginnen!"
                        lazy={true}
                      />
                    )}
                  </Grid>
                </Grid>
              </ProfileBox>
              <ProfileBox>
                <>
                  <HeadlineContainer headline="Kompetenzen" headlineVariant={isMdUp ? 'h3boosted' : 'h3'} hideUnderline />
                  <Bx pb={2}>
                    <Typo variant="body1" component="span">
                      Die Angabe Ihrer Kompetenzen dient dazu, Ihnen Inhalte und Anfragen personalisiert zuzustellen, bei denen Ihre Expertise gefragt ist. Ihre Einträge sind für andere Nutzende sichtbar.
                    </Typo>
                  </Bx>
                  <FormCategorySelect
                    value={profile?.competenceCategories?.map(c => c.categoryId) ?? []}
                    categoryTree={filterTreeById(categoryTree, '33d73fd4-a973-eac0-5a0b-4389d8ec11a8', false)}
                    categoryRepository={items}
                    label={'Innovation'}
                    placeholder={'Sie haben noch keine Kompetenzen ausgewählt.'}
                    placeholderSearch={'Kompetenzen finden...'}
                    buttonTitle={'Kompetenzen hinzufügen'}
                    minLength={0}
                    maxLength={0}
                    onChange={id => toggleCategory(true, id)}
                    noEdit={!isMyProfile || !settings.isEditing}
                  />
                  <FormCategorySelect
                    value={profile?.competenceCategories?.map(c => c.categoryId) ?? []}
                    categoryTree={filterTreeById(categoryTree, '2534fffe-6239-312f-facd-39f28059fda9', false)}
                    categoryRepository={items}
                    label={'Medizin'}
                    placeholder={'Sie haben noch keine Kompetenzen ausgewählt.'}
                    placeholderSearch={'Kompetenzen finden...'}
                    buttonTitle={'Kompetenzen hinzufügen'}
                    minLength={0}
                    maxLength={0}
                    onChange={id => toggleCategory(true, id)}
                    noEdit={!isMyProfile || !settings.isEditing}
                    allowParentSelection={true}
                  />
                  <FormCategorySelect
                    value={profile?.competenceCategories?.map(c => c.categoryId) ?? []}
                    categoryTree={filterTreeById(categoryTree, 'dc173352-54c4-5809-8618-5d250ed9b597', false)}
                    categoryRepository={items}
                    label={'Medizinprodukte'}
                    placeholder={'Sie haben noch keine Kompetenzen ausgewählt.'}
                    placeholderSearch={'Kompetenzen finden...'}
                    buttonTitle={'Kompetenzen hinzufügen'}
                    minLength={0}
                    maxLength={0}
                    onChange={id => toggleCategory(true, id)}
                    noEdit={!isMyProfile || !settings.isEditing}
                  />
                  <FormCategorySelect
                    value={profile?.competenceCategories?.map(c => c.categoryId) ?? []}
                    categoryTree={filterTreeById(categoryTree, 'cd87ce7f-7823-80cc-1136-00eddc710bc9', false)}
                    categoryRepository={items}
                    label={'Wissenschaft'}
                    placeholder={'Sie haben noch keine Kompetenzen ausgewählt.'}
                    placeholderSearch={'Kompetenzen finden...'}
                    buttonTitle={'Kompetenzen hinzufügen'}
                    minLength={0}
                    maxLength={0}
                    onChange={id => toggleCategory(true, id)}
                    noEdit={!isMyProfile || !settings.isEditing}
                    allowParentSelection={true}
                    parentSelectionStartDeepth={1}
                  />
                </>
              </ProfileBox>
              <ProfileBox>
                <>
                  <HeadlineContainer headline="Interessen" headlineVariant={isMdUp ? 'h3boosted' : 'h3'} hideUnderline />
                  <Bx pb={2}>
                    <Typo variant="body1" component="span">
                      Die Angabe Ihrer Interessen dient dazu, Ihnen Informationen personalisiert zuzustellen, die sich mit Ihren Interessen decken. Ihre Einträge sind für andere Nutzende sichtbar.
                    </Typo>
                  </Bx>
                  <FormCategorySelect
                    value={profile?.interestCategories?.map(c => c.categoryId) ?? []}
                    categoryTree={filterTreeById(categoryTree, '33d73fd4-a973-eac0-5a0b-4389d8ec11a8', false)}
                    categoryRepository={items}
                    label={'Innovation'}
                    placeholder={'Sie haben noch keine Interessen ausgewählt.'}
                    placeholderSearch={'Interessen finden...'}
                    buttonTitle={'Interessen hinzufügen'}
                    minLength={0}
                    maxLength={0}
                    onChange={id => toggleCategory(false, id)}
                    noEdit={!isMyProfile || !settings.isEditing}
                  />
                  <FormCategorySelect
                    value={profile?.interestCategories?.map(c => c.categoryId) ?? []}
                    categoryTree={filterTreeById(categoryTree, '2534fffe-6239-312f-facd-39f28059fda9', false)}
                    categoryRepository={items}
                    label={'Medizin'}
                    placeholder={'Sie haben noch keine Interessen ausgewählt.'}
                    placeholderSearch={'Interessen finden...'}
                    buttonTitle={'Interessen hinzufügen'}
                    minLength={0}
                    maxLength={0}
                    onChange={id => toggleCategory(false, id)}
                    noEdit={!isMyProfile || !settings.isEditing}
                    allowParentSelection={true}
                  />
                  <FormCategorySelect
                    value={profile?.interestCategories?.map(c => c.categoryId) ?? []}
                    categoryTree={filterTreeById(categoryTree, 'dc173352-54c4-5809-8618-5d250ed9b597', false)}
                    categoryRepository={items}
                    label={'Medizinprodukte'}
                    placeholder={'Sie haben noch keine Interessen ausgewählt.'}
                    placeholderSearch={'Interessen finden...'}
                    buttonTitle={'Interessen hinzufügen'}
                    minLength={0}
                    maxLength={0}
                    onChange={id => toggleCategory(false, id)}
                    noEdit={!isMyProfile || !settings.isEditing}
                  />
                  <FormCategorySelect
                    value={profile?.interestCategories?.map(c => c.categoryId) ?? []}
                    categoryTree={filterTreeById(categoryTree, 'cd87ce7f-7823-80cc-1136-00eddc710bc9', false)}
                    categoryRepository={items}
                    label={'Wissenschaft'}
                    placeholder={'Sie haben noch keine Interessen ausgewählt.'}
                    placeholderSearch={'Interessen finden...'}
                    buttonTitle={'Interessen hinzufügen'}
                    minLength={0}
                    maxLength={0}
                    onChange={id => toggleCategory(false, id)}
                    noEdit={!isMyProfile || !settings.isEditing}
                    allowParentSelection={true}
                    parentSelectionStartDeepth={1}
                  />
                </>
              </ProfileBox>
              <ProfileBox>
                <>
                  <HeadlineContainer mb={2} headline="Primärer Arbeitgeber" headlineVariant={isMdUp ? 'h3boosted' : 'h3'} hideUnderline />
                  <Bx pt={2}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        {!editPrimaryPlayerActive && (
                          <HeadlineContainer
                            mb={3}
                            headline={!!profile.player && profile.player.length > 0 ? profile.player[0].playerResolved?.name ?? '' : ''}
                            headlineVariant="h4boosted"
                            // hideUnderline
                            endAdornment={
                              <Bx ml={1}>
                                <Hidden smDown>
                                  <Btn variant="outlined" endIcon={<FontAwesomeIcon icon={faEdit} />} onClick={() => setEditPrimaryPlayerActive(true)}>
                                    primären Arbeitgeber bearbeiten
                                  </Btn>
                                </Hidden>
                                <Hidden mdUp>
                                  <IconBtn variant="outlined" size="small" shape="rounded" onClick={() => setEditPrimaryPlayerActive(true)}>
                                    <FontAwesomeIcon icon={faEdit} />
                                  </IconBtn>
                                </Hidden>
                              </Bx>
                            }
                          />
                        )}
                        {editPrimaryPlayerActive && (
                          <EmployerSelect
                            label={'Institution'}
                            sublabel={'Department'}
                            preset={userSelectedEmployerFirstLevel}
                            subpreset={userSelectedEmployerSecondLevel}
                            onSelectFirstLevel={actorData => {
                              const subtype = actorData?._source?.content?.subtype;
                              if (subtype === 'hospital' || subtype === 'university' || subtype === 'public research center' || subtype === 'non-public research facility') {
                                setSetDepartment(true);
                              }
                              setUserSelectedEmployerFirstLevel(actorData);
                              setUserSelectedEmployerSecondLevel(null);
                              settings.setDisableSave(true);
                            }}
                            onSelectSecondLevel={actorData => {
                              setUserSelectedEmployerSecondLevel(actorData);
                            }}
                            onSelect={(actorData, level) => {
                              const swap = Array.from(profile.player ?? [{}]);
                              const player: IUserProfilePlayer = {
                                player: actorData?._id,
                                playerResolved: {
                                  name: actorData?._source.content.name,
                                  alias: actorData?._source.seo.alias
                                }
                              };
                              if (level === 'second') {
                                const playerRoot = userSelectedEmployerFirstLevel as any;
                                player.playerRoot = playerRoot._id;
                                player.playerRootResolved = {
                                  name: playerRoot?._source.content.name,
                                  alias: playerRoot?._source.seo.alias
                                };
                              }
                              swap.splice(0, 1, player);
                              setProfile({
                                ...profile,
                                department: setDepartment && level === 'second' ? actorData?._source.content.name || '' : '',
                                organisation: level === 'second' ? (userSelectedEmployerFirstLevel as any)?._source.content.name : actorData?._source.content.name,
                                player: swap
                              });
                              settings.setDisableSave(false);
                              setSetDepartment(false);
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormInput
                          label="Funktion"
                          minLength={0}
                          maxLength={60}
                          placeholder="keine Angabe"
                          value={profile.player?.[0]?.workFunction || ''}
                          onChange={val => {
                            const swap = Array.from(profile.player ?? [{}]);
                            swap.splice(0, 1, { ...profile.player?.[0], workFunction: val });
                            setProfile({ ...profile, function: val.trim(), player: swap });
                          }}
                          successMessage=""
                          lazy={true}
                        />
                      </Grid>
                    </Grid>
                  </Bx>
                </>
              </ProfileBox>
              <ProfileBox>
                <HeadlineContainer mb={2} headline="Weitere Arbeitgeber" headlineVariant={isMdUp ? 'h3boosted' : 'h3'} hideUnderline />
                {!!profile && !!profile.player && profile.player.length > 0 && (
                  <>
                    <ListWrapper divider="bottom">
                      {profile.player.slice(1).map((p, i) => {
                        return (
                          <Bx pt={i === 0 ? 2 : 4} pb={2} key={i}>
                            <HeadlineContainer
                              mb={3}
                              headline={p.playerResolved?.name}
                              headlineVariant="h4boosted"
                              // hideUnderline
                              endAdornment={
                                <Bx ml={1}>
                                  <Hidden smDown>
                                    <Btn
                                      variant="outlined"
                                      color="quaternary"
                                      endIcon={<FontAwesomeIcon icon={faTrash} />}
                                      onClick={() => {
                                        setProfile({ ...profile, player: profile.player?.filter(f => f !== p) });
                                      }}
                                    >
                                      Arbeitgeber entfernen
                                    </Btn>
                                  </Hidden>
                                  <Hidden mdUp>
                                    <IconBtn
                                      color="quaternary"
                                      variant="outlined"
                                      size="small"
                                      shape="rounded"
                                      onClick={() => {
                                        setProfile({ ...profile, player: profile.player?.filter(f => f !== p) });
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </IconBtn>
                                  </Hidden>
                                </Bx>
                              }
                            />
                            <Grid container spacing={4}>
                              <Grid item xs={12} md={6}>
                                <FormInput
                                  label="Funktion"
                                  type="text"
                                  minLength={0}
                                  maxLength={100}
                                  value={p.workFunction ?? ''}
                                  lazy
                                  onChange={val => {
                                    const swap = Array.from(profile.player ?? [{}]);
                                    swap.splice(i + 1, 1, { ...p, workFunction: val });
                                    setProfile({ ...profile, player: swap });
                                  }}
                                  successMessage=""
                                  placeholder="keine Angabe"
                                />
                              </Grid>
                            </Grid>
                          </Bx>
                        );
                      })}
                    </ListWrapper>
                  </>
                )}

                {settings.isEditing && (
                  <Bx mt={8}>
                    <EmployerSelect
                      label={'Arbeitgeber hinzufügen'}
                      sublabel={'Department'}
                      preset={userSelectedAdditionalEmployerFirstLevel}
                      subpreset={userSelectedAdditionalEmployerSecondLevel}
                      onSelectFirstLevel={actorData => {
                        setUserSelectedAdditionalEmployerFirstLevel(actorData);
                        setUserSelectedAdditionalEmployerSecondLevel(null);
                      }}
                      onSelectSecondLevel={actorData => {
                        setUserSelectedAdditionalEmployerSecondLevel(actorData);
                      }}
                      onSelect={(actorData, level) => {
                        const player: IUserProfilePlayer = {
                          player: actorData?._id,
                          playerResolved: {
                            name: actorData?._source.content.name,
                            alias: actorData?._source.seo.alias
                          }
                        };
                        if (level === 'second') {
                          const playerRoot = userSelectedAdditionalEmployerFirstLevel as any;
                          player.playerRoot = playerRoot._id;
                          player.playerRootResolved = {
                            name: playerRoot?._source.content.name,
                            alias: playerRoot?._source.seo.alias
                          };
                        }
                        setUserSelectedAdditionalEmployerFirstLevel(null);
                        setUserSelectedAdditionalEmployerSecondLevel(null);
                        setProfile({
                          ...profile,
                          player: [...(profile.player ?? [{}]), player]
                        });
                      }}
                    />
                  </Bx>
                )}
              </ProfileBox>
            </>
          )}

          {/* Edit View -> Settings */}
          {!!profile && isMyProfile && isSettings && !isAdmin && !isInvite && !isExpertRegistration && settings.isEditing && (
            <>
              <ProfileBox>
                <HeadlineContainer mb={6} headline="Passwort ändern" headlineVariant={isMdUp ? 'h3boosted' : 'h3'} hideUnderline />
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <FormInput
                      label="Altes Passwort"
                      type="password"
                      minLength={0}
                      maxLength={0}
                      value=""
                      onChange={val => setPwdChange({ ...pwdChange, oldPassword: val })}
                      successMessage=""
                      placeholder=""
                      autoTrim
                      invalidMessage=""
                      noLengthWarning={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                  <Grid item xs={12} md={6}>
                    <FormInput
                      label="Neues Passwort"
                      type="password"
                      minLength={8}
                      maxLength={0}
                      value=""
                      onChange={val => setPwdChange({ ...pwdChange, newPassword: val })}
                      successMessage=""
                      placeholder=""
                      autoTrim
                      noLengthWarning
                      invalidMessage=""
                      validator={passwordValid}
                      fieldGroup={'passwordData'}
                    />
                    <Bx mt={1}>
                    {!!pwdChange.newPassword && !!getPasswordInvalidMsg()
                      ? <InputInfoText type="error">
                          <Typo component='span' variant='body3boosted'>{getPasswordInvalidMsg()}</Typo>
                        </InputInfoText>
                      : <InputInfoText>
                          <Typo component='span' variant='body3boosted'>&nbsp;</Typo>
                        </InputInfoText>
                    }
                    </Bx>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormInput
                      label="Neues Passwort (Wiederholung)"
                      type="password"
                      minLength={8}
                      maxLength={0}
                      value=""
                      onChange={val => setPwdChange({ ...pwdChange, newPasswordRepeat: val })}
                      successMessage=""
                      placeholder=""
                      autoTrim
                      noLengthWarning
                    />
                    <Bx mt={1}>
                    {!!pwdChange.newPasswordRepeat && pwdChange.newPasswordRepeat !== pwdChange.newPassword &&
                      <InputInfoText type="error">
                        <Typo component='span' variant='body3boosted'>
                          Die Passwörter stimmen nicht überein!
                        </Typo>
                      </InputInfoText>
                    }
                    </Bx>
                  </Grid>
                </Grid>

                <Bx mt={1}>
                  {detailUpdatePassSuccess && <Typo color="success.main">
                    Passwort wurde erfolgreich gespeichert!
                  </Typo>}
                  {detailUpdatePassError && <Typo color="error.main">
                    Altes Passwort ist falsch.
                  </Typo>}
                </Bx>

                <Bx mt={6} display="flex" flexDirection="row" justifyContent="space-between">
                  <Btn size="large" startIcon={<FontAwesomeIcon icon={faTimes} />} variant="outlined" color="default" onClick={() => {setChangePwd(false); settings.onCancel?.();}}>
                    Abbrechen
                  </Btn>
                  <Btn
                    disabled={!passwordValid(pwdChange.newPassword) || (!!pwdChange.newPassword && pwdChange.newPassword !== pwdChange.newPasswordRepeat)}
                    size="large"
                    startIcon={<FontAwesomeIcon icon={faKey} />}
                    variant="contained"
                    color="success"
                    onClick={() => {
                      setChangePwd(false);
                      dispatch(userprofileUpdatePassword(pwdChange));
                    }}
                  >
                    Neues Passwort speichern
                  </Btn>
                </Bx>
              </ProfileBox>
            </>
          )}

          {!!profile && isMyProfile && !isSettings && isAdmin && !isInvite && !isExpertRegistration && (
            <ProfileBox>
              <TokenAdminComponent />
            </ProfileBox>
          )}
          {!!profile && isMyProfile && !isSettings && !isAdmin && isInvite && !isExpertRegistration && (
            <ProfileBox>
              <BlacklistInviteComponent />
            </ProfileBox>
          )}
          {!!profile && isMyProfile && !isSettings && !isAdmin && !isInvite && isExpertRegistration && (
            <ProfileBox>
              <ExpertRegistrationComponent />
            </ProfileBox>
          )}
        </>
        {/* )} */}

        {/* Working... */}
        {/* {detailWorking && <div>Working...</div>} */}
        {!keyVisualFiles[0]?.preview && (
          <Bx>
            <Typo variant="subtitle2">{'Hintergrundbild: ' + TYPES_PROPS[TYPES.PROFILE]?.FallbackImage?.copyright}</Typo>
          </Bx>
        )}
      </FramedContent>
    </>
  );
};

interface IProfileHeadProps {
  type?: string;
  userName?: string;
  userFunction?: string;
  isMdUp?: boolean;
}

const ProfileHead: React.FC<IProfileHeadProps> = props => {
  return (
    <>
      {/* Type */}
      {props.type && (
        <Bx display="flex" justifyContent="space-between" alignItems="center" mb={5}>
          <Bx>
            <Typo variant="subtitle2" component="span" textTransform="uppercase">
              {props.type}
            </Typo>
          </Bx>
        </Bx>
      )}

      {/* Name & Function */}
      <Bx mb={3.5}>
        <Typo variant={props.isMdUp ? 'h1boosted' : 'h1'} component="h1" gutterBottom>
          {props.userName}
          {props.userFunction && (
            <Bx mt={1}>
              <Typo variant={props.isMdUp ? 'h4boosted' : 'h4'}>{props.userFunction}</Typo>
            </Bx>
          )}
        </Typo>
      </Bx>
    </>
  );
};

interface IProfileMetaBarProps {
  share?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  userId?: string;
  onRequest: (id: string) => void;
  booked?: boolean;
  memoryClick?: () => void;
}

const ProfileMetaBar: React.FC<IProfileMetaBarProps> = props => {
  return (
    <>
      {!props.share && !props.userId ? (
        <></>
      ) : (
        <Bx mb={4}>
          <Bx borderColor="divider" border={1} borderLeft={0} borderRight={0} height={74}>
            <Bx display="flex" alignItems="center" height="100%" py={0.5}>
              {props.userId && <OneToOneCommunicationStateButton userId={props.userId} onRequest={() => props.userId && props.onRequest(props.userId)} />}
              {props.share && (
                <Bx ml={'auto'} display="flex" justifyContent="center" alignItems="center">
                  <Hidden smDown>
                    <Btn onClick={props.share} color="primary" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faShare} />}>
                      Teilen
                    </Btn>
                  </Hidden>
                  <Hidden mdUp>
                    <IconBtn onClick={props.share} color="primary" size="medium" variant="contained" shape="rounded">
                      <FontAwesomeIcon icon={faShare} />
                    </IconBtn>
                  </Hidden>
                </Bx>
              )}
              {!!props.memoryClick && (
                <Bx ml={1} display="flex" justifyContent="center" alignItems="center">
                  <Hidden smDown>
                    <Btn
                      onClick={props.memoryClick}
                      color="primary"
                      size="large"
                      variant={props.booked ? 'contained' : 'outlined'}
                      startIcon={
                        <FontAwesomeIcon icon={props.booked ? faBookmark : faBookmarkLight} />
                      }
                    >
                      {props.booked ? 'Gemerkt' : 'Merken'}
                    </Btn>
                  </Hidden>
                  <Hidden mdUp>
                    <IconBtn color="primary" size="medium" variant="contained" shape="rounded" onClick={props.memoryClick}>
                      {<FontAwesomeIcon icon={props.booked ? faBookmark : faBookmarkLight} />}
                    </IconBtn>
                  </Hidden>
                </Bx>
              )}
            </Bx>
          </Bx>
        </Bx>
      )}
    </>
  );
};
