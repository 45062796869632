import React, { useEffect } from 'react';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { IDetailComponent } from '../';
import { ConnectedCommunicationActionCanvas } from '../../communication/action-canvas';
import { AppFrameContent, AppFrameHeader } from '../../../components/frame';
import { ThingTypes } from '../../../model/ryve/Thing';
import {
  invitationResponseRequest,
  requestParticipationRequest,
  revokeRequestRequest,
  setActionCanvas,
  setMessageDraftContent,
  setMessageDraftQuotes,
  setMessageDraftThreadRoot
} from '../../../data/reducer/communication';
import { ConnectedDetailHeaderComponent, IDetailHeaderProps } from '../../../components/detail/header';
import { BorderedContentPendingParticipationComponent } from '../../../components/detail/participation';
import {
  resetFormData,
  updateFormData,
  updateFormDataAttribute,
  updateDetailRequest,
  setDetailUpdate,
  cancelMeetingRequest,
  fetchMeetingParticipantsRequest
} from '../../../data/reducer/detail';
import { ConnectedHeader, ICommunicationAction, Tab } from '../../../components/communication/header';
import { Anohana, Btn, Bx, Typo } from '@curry-group/mui-curcuma';
import { FormCategories } from '../../../components/form/compositions/FormCategories';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/pro-light-svg-icons';
import { ParticipationRequestStatus, ParticipationRequestType } from '../../../model/communication/Participant';
import { Grid } from '@material-ui/core';
import { FormInput, FormValidator } from '../../../components/form/controls/input';
import { FormIdea } from '../../../components/form/compositions/FormIdea';
import { FormQnA } from '../../../components/form/compositions/FormQnA';
import { FormRequisition } from '../../../components/form/compositions/FormRequisition';
import { GetExistingAttachments, getReferrer } from '../../../helper';
import { useHistory } from 'react-router-dom';
import { IContentInteractionBarProps } from '../../../components/content-interaction-bar';
import { DetailSwitcherComponent } from '../../../components/detail/connected';
import { FormMeeting } from '../../../components/form/compositions/FormMeeting';
import { IMessageModel } from '../../../model/communication/Message';
import { LandingpageHeaderImpl } from '../../landingpage/impl/header';
import { LandingpageFooterImpl } from '../../landingpage/impl/footer';
import { FramedContent } from '../../../components/framed-content';
import { ConnectedSettingsMeetingParticipants } from './meeting/settings/participants';
import { MeetingInvite } from './meeting/invite';
import { CancelAcceptInputDialog } from '../../../components/dialogs/cancel-accept-input';
import { ConnectedMeetingParticipantModal } from './meeting/settings/modal';

export const ContentDetail: React.FC<
  IDetailComponent &
    Pick<IDetailHeaderProps, 'backButtonLink'> & {
      chatButton: IContentInteractionBarProps['chatButton'];
      hideComments?: boolean;
      categories?: any;
      config?: any;
    }
> = ({ isScrolled, setIsScrolled, backButtonLink, isMobile, chatButton, hideComments, canEdit, categories, config }) => {
  const [view, setView] = useState<'content' | 'settings' | 'requestParticipation' | 'meetingInvite'>('content');
  const location = useLocation();
  const history = useHistory();
  const participation = useSelector(state => state.communication.participation);
  const userId = useSelector(state => state.foundation.profile?.userId);
  const allowRequests = useSelector(state => state.communication?.communication?.allowRequests);
  const updateRequestSent = useSelector(state => state.detail.update);
  const auth = useSelector(state => state.foundation.auth);
  const dispatch = useDispatch();
  const actioncanvas = useSelector(state => state.communication?.actioncanvas);
  const calledMessageInContentPage = useSelector(state => state.communication?.calledMessageInContentPage);

  const detailItem = useSelector(state => state.detail.item);
  const detailId = useSelector(state => state.detail.item?._id);
  const createdBy = useSelector(state => state.detail.item?.createdBy);
  const isMeeting = useSelector(state => state.detail?.item?.type === ThingTypes.Meeting);
  const meetingEnded = useSelector(state => state.detail?.item?.content?.meetingEnded);
  const cancelled = useSelector(state => state.detail?.item?.content?.cancelled);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (updateRequestSent) {
    setView('content');
    dispatch(setDetailUpdate({ update: false }));
  }
  const editAllowed = canEdit && userId === createdBy;
  function commentsClick(message: IMessageModel | null = null) {
    // if (!conversationMounted) setConversationMounted(true);
    dispatch(setMessageDraftThreadRoot({ id: 'actioncanvas', threadRoot: message || undefined }));
    dispatch(setMessageDraftQuotes({ id: 'actioncanvas', quotes: undefined }));
    dispatch(setMessageDraftContent({ id: 'actioncanvas', content: '' }));
    dispatch(setActionCanvas({ open: true, threadRoot: message || undefined }));
  }

  const getChatButton = (): IContentInteractionBarProps['chatButton'] => {
    if (!chatButton || hideComments) return {};

    if (chatButton.type === 'apply' && allowRequests)
      return {
        ...chatButton,
        onClick: () => setView('requestParticipation')
      };

    if (chatButton.type === 'participate' && participation) {
      if (participation.type === ParticipationRequestType.PUBLIC) return chatButton;
      if (participation.status === ParticipationRequestStatus.PENDING && participation.type === ParticipationRequestType.REQUEST)
        return {
          type: 'apply'
        };
    }

    if (chatButton.type === 'discussion' && participation)
      return {
        ...chatButton,
        onClick: commentsClick
      };

    return {};
  };
  let noAuthBackButtonAction = getReferrer(location.search)
                                  ? () => history.push(getReferrer(location.search) || '/')
                                  : () => history.push('/');

  return (
    <>
      {view === 'settings' && <ConnectedDetailSettings close={() => setView('content')} categories={categories} config={config} />}
      {view === 'requestParticipation' && (
        <ParticipationRequest close={() => setView('content')} submit={message => detailId && dispatch(requestParticipationRequest({ id: detailId, message: message }))} />
      )}
      {view === 'meetingInvite' && <MeetingInvite onClose={() => setView('content')} />}
      {view === 'content' && (
        <>
          <AppFrameHeader>
            {auth && (
              <ConnectedDetailHeaderComponent
                backButtonLink={backButtonLink}
                show={isScrolled}
                chatButton={getChatButton()}
                editClick={
                  editAllowed
                    ? () => {
                        setView('settings');
                      }
                    : undefined
                }
              />
            )}
          </AppFrameHeader>
          <AppFrameContent>
            {!auth && <LandingpageHeaderImpl view="other" scrolled backButtonLink={noAuthBackButtonAction} auth={auth} />}
            <DetailSwitcherComponent
              top={meetingEnded || cancelled
                ? <></>
                : <BorderedContentPendingParticipationComponent
                  participation={participation}
                  onRevoke={() => {
                    detailId && dispatch(revokeRequestRequest({ id: detailId }));
                  }}
                  onAccept={() => {
                    detailId && dispatch(invitationResponseRequest({ id: detailId, accepted: true }));
                  }}
                  onDecline={() => {
                    detailId && dispatch(invitationResponseRequest({ id: detailId, accepted: false }));
                  }}
                  meetingEndedOrCancelled={meetingEnded || cancelled}
                />
              }
              chatButton={getChatButton()}
              editClick={
                editAllowed
                  ? () => {
                      setView('settings');
                    }
                  : undefined
              }
              inviteClick={
                editAllowed && isMeeting
                  ? () => {
                      setView('meetingInvite');
                    }
                  : undefined
              }
              setIsScrolled={setIsScrolled}
              isScrolled={isScrolled}
              onRequestActionCanvas={msg => {
                commentsClick(msg);
              }}
            />
            {(actioncanvas?.open || !!calledMessageInContentPage || false) &&
              <ConnectedCommunicationActionCanvas
                request={{}}
                headline="Diskussion"
                detailItem={detailItem ?? undefined}
              />}
            {!auth && <LandingpageFooterImpl />}
          </AppFrameContent>
        </>
      )}
    </>
  );
};

let defaultSettingsTabs: Tab[] = [
  {
    title: 'Allgemein',
    alias: 'common',
    type: 'view'
  },
  {
    title: 'Kategorien',
    alias: 'categories',
    type: 'view'
  }
];
let meetingSettingsTabs: Tab[] = [
  {
    title: 'Allgemein',
    alias: 'common',
    type: 'view'
  },
  {
    title: 'Teilnehmende',
    alias: 'participants',
    type: 'view'
  }
];

export interface IDetailSettingsProps {
  type: string;
  formData: any;
  categories: any;
  config: any;
  close: () => void;
  updateFormData: (formData: any) => void;
  updateFormDataAttribute: (formField: string, formValue: any, formName?: string) => void;
  resetFormData: () => void;
  save: (customMessage?: string) => void;
  //specific functions
  cancelMeeting?: (customMessage?) => void;
}

export const DetailSettings: React.FC<IDetailSettingsProps> = ({
  type,
  formData,
  categories,
  config,
  close,
  updateFormData,
  updateFormDataAttribute,
  resetFormData,
  save,
  cancelMeeting
}) => {
  const dispatch = useDispatch();

  const [nextStepDisabled, setNextStepDisabled] = useState(true);
  const formTypeName = type === ThingTypes.Meeting ? 'meeting' : null;
  const settingsTabs = type === ThingTypes.Meeting ? meetingSettingsTabs : defaultSettingsTabs;
  const [selectedSettingsTab, setSelectedSettingsTab] = useState(settingsTabs[0]);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [customSaveMessageDialog, setCustomSaveMessageDialog] = useState(false);

  const specialValidation = (formTypeName: string, formData: any) => {
    if (formTypeName === 'meeting') {
      return !formData?.itemData?.interval
      || (formData?.itemData?.external && (
        (!formData?.itemData?.location && !formData?.itemData?.externalLink)
        || (formData?.itemData?.externalType === 'link' && (!formData?.itemData?.externalLink || !formData?.itemData?.externalLinkAcceptedInstructions))
        || (formData?.itemData?.externalType === 'location' && !formData?.itemData?.location)
      ))
    }
    return false;
  };

  const editActions: Array<ICommunicationAction> = [
    {
      onClick: () => {
        setCancelModalOpen(true);
      },
      icon: <FontAwesomeIcon icon={faTimes} />,
      specificType: 'Btn',
      title: 'Abbrechen',
      color: 'error',
      size: 'large',
      variant: 'outlined'
    }
  ];
  const saveClick: ICommunicationAction = {
    onClick: () => { save?.() },
    icon: <FontAwesomeIcon icon={faSave} />,
    specificType: 'Btn',
    title: 'Speichern',
    color: 'success',
    size: 'large',
    variant: 'contained',
    disabled: formTypeName ? (!FormValidator.isValid('', formTypeName) || specialValidation(formTypeName, formData)) && nextStepDisabled : false
  }
  if (type === ThingTypes.Meeting) {
    saveClick.onClick = () => setCustomSaveMessageDialog(true);
  }
  editActions.unshift(saveClick);
  const cancelAction = () => {
    setCancelModalOpen(true);
    close();
    resetFormData();
  };

  const selectTab = (tab: Tab) => {
    setSelectedSettingsTab(tab);
    if (type === ThingTypes.Meeting && tab.alias === 'participants') {
      dispatch(fetchMeetingParticipantsRequest());
    }
  };

  return (
    <>
      <AppFrameHeader>
        <ConnectedHeader
          actions={editActions}
          tabs={settingsTabs.map(t => t.title)}
          title="Einstellungen"
          selectTab={index => selectTab(settingsTabs[index])}
          selectedTab={settingsTabs.map(t => t.title).indexOf(selectedSettingsTab.title) >= 0 ? settingsTabs.map(t => t.title).indexOf(selectedSettingsTab.title) : 0}
          isSettings={true}
        />
      </AppFrameHeader>
      <AppFrameContent>
        {type === ThingTypes.Meeting && <ConnectedMeetingParticipantModal />}
        {selectedSettingsTab.alias === 'common' && (
          <FramedContent>
            {type === ThingTypes.Idea && <FormIdea formData={formData} onChange={updateFormData} />}
            {type === ThingTypes.QnA && <FormQnA formData={formData} onChange={updateFormData} />}
            {type === ThingTypes.Requisition && <FormRequisition formData={formData} onChange={updateFormData} />}
            {type === ThingTypes.Meeting && (
              <FormMeeting
                formData={formData}
                onChange={updateFormDataAttribute}
                onChangeAttachments={updateFormData}
                setValid={valid => setNextStepDisabled(!valid)}
                cancelMeeting={cancelMeeting}
              />
            )}
          </FramedContent>
        )}
        {selectedSettingsTab.alias === 'categories' && (
          <FramedContent>
            <FormCategories headline="" subtitle="" config={config} formData={formData} categories={categories} onChange={updateFormData} />
          </FramedContent>
        )}
        {selectedSettingsTab.alias === 'participants' && (
          <FramedContent>
            <ConnectedSettingsMeetingParticipants />
          </FramedContent>
        )}
        <Anohana open={cancelModalOpen} transition="zoom" disableScrollLock fullWidth maxWidth="sm" onClose={() => setCancelModalOpen(false)}>
          <Bx p={2.5}>
            <Bx justifyContent="center" mb={3}>
              <Typo variant="h4" component="span">
                Wollen Sie wirklich abbrechen? Alle Änderungen gehen dabei verloren.
              </Typo>
            </Bx>
            <Bx display="flex" mt={3}>
              <Bx>
                <Btn variant="contained" color="success" size="large" onClick={() => setCancelModalOpen(false)}>
                  Zurück
                </Btn>
              </Bx>
              <Bx ml="auto">
                <Btn variant="outlined" color="error" size="large" onClick={cancelAction}>
                  Abbrechen bestätigen
                </Btn>
              </Bx>
            </Bx>
          </Bx>
        </Anohana>
        <CancelAcceptInputDialog
          headerText="Sie können optional einen Grund für die Aktualisierung angeben."
          labelText="Grund"
          placeholderText="Bitte einen Grund angeben ..."
          open={customSaveMessageDialog}
          onCanceled={() => {
            setCustomSaveMessageDialog(false);
          }}
          onAccepted={save}
        />
      </AppFrameContent>
    </>
  );
};

interface IConnectedDetailSettings {
  close: () => void;
  categories: any;
  config: any;
}

export const ConnectedDetailSettings: React.FC<IConnectedDetailSettings> = ({ close, categories, config }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let formData = useSelector(state => state.detail?.formData);
  const attachmentsSubD = useSelector(state => state.detail?.item?.content?.attachmentsSubD);
  const detailId = useSelector(state => state.detail.item?._id);
  const detailType = useSelector(state => state.detail.item?.type);
  const detailContent = useSelector(state => state.detail.item?.content);
  const detailCategories = useSelector(state => state.detail.item?.categories);
  if (!formData || formData._id !== detailId) {
    const existingAttachments = GetExistingAttachments(attachmentsSubD || []);
    formData = {
      _id: detailId,
      avatar: undefined,
      keyvisual: undefined,
      itemData: detailContent,
      attachments: [...(existingAttachments || [])],
      categories: detailCategories?.map(cat => cat.categoryId)
    };
    dispatch(updateFormData?.({ formData }));
  }
  const dispatcher = {
    updateFormData: (formData: any) => dispatch(updateFormData?.({ formData })),
    updateFormDataAttribute: (formField: string, formValue: any, formName?: string) => dispatch(updateFormDataAttribute?.({ formField, formValue, formName })),
    resetFormData: () => dispatch(resetFormData?.({})),
    updateDetailRequest: (customMessage?: string) => dispatch(updateDetailRequest?.({ history, customMessage })),
    //Meeting-Specific
    cancelMeetingRequest:
      detailType === ThingTypes.Meeting && !!detailId
        ? (customMessage?: string) => dispatch(cancelMeetingRequest({ id: detailId, onSuccess: close, customMessage }))
        : undefined
  };

  if (!detailType) return <></>;
  return (
    <DetailSettings
      close={close}
      type={detailType}
      formData={formData}
      categories={categories}
      config={config}
      updateFormData={dispatcher.updateFormData}
      updateFormDataAttribute={dispatcher.updateFormDataAttribute}
      resetFormData={dispatcher.resetFormData}
      save={dispatcher.updateDetailRequest}
      cancelMeeting={dispatcher.cancelMeetingRequest}
    />
  );
};

interface IParticipationRequestProps {
  close: () => void;
  submit: (any) => void;
}

const ParticipationRequest: React.FC<IParticipationRequestProps> = ({ close, submit }) => {
  const [requestText, setRequestText] = useState<string>('');
  return (
    <>
      <AppFrameHeader>
        <ConnectedHeader title={' '} backButtonAction={close} />
      </AppFrameHeader>
      <AppFrameContent>
        <FramedContent>
          <Bx mb={1}>
            <Typo variant="h1" component="h1">
              Noch ein paar Worte zu Ihrer Beitrittsanfrage?
            </Typo>
          </Bx>
          <Typo variant="subtitle1">
            Wir freuen uns, etwas über Sie zu erfahren: Was ist Ihr Interesse an der Gruppe? Was Ihre Motivation zur Teilnahme am Projekt? Wie wollen Sie sich mit einbringen?
          </Typo>
          <Bx my={8}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={12}>
                <FormInput
                  value={requestText}
                  label={'Ihre Worte zum Beitritt'}
                  placeholder={'Erzählen Sie uns mehr...'}
                  multiline={true}
                  minLength={5}
                  maxLength={300}
                  onChange={text => setRequestText(text)}
                />
              </Grid>
            </Grid>
          </Bx>
          <Bx display="flex" justifyContent="flex-end">
            <Btn
              color="success"
              size="large"
              variant="contained"
              onClick={() => {
                submit(requestText);
                close();
              }}
            >
              Anfrage abschicken
            </Btn>
          </Bx>
        </FramedContent>
      </AppFrameContent>
    </>
  );
};
