import { Typo, Link, Btn, Bx } from '@curry-group/mui-curcuma';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { MessageConferenceContent, MessageContent } from '../content';
import { getProfileRouteByUserId } from '../../../../helper';
import { IMessageModel, IMessageReferences } from '../../../../model/communication/Message';

export interface IMessageQuoteProps {
  message: IMessageModel;
  remove?: () => void;
  onJoinConference?: () => void;
  references?: IMessageReferences;
  onReferenceSelected?: (type: string, data: any) => void;
  quoteOriginLink?: string;
  quoteOriginTitle?: string;
  specialAuthor?: string;
}

export const MessageQuote: React.FC<IMessageQuoteProps> = ({
  message,
  remove,
  onJoinConference,
  references,
  onReferenceSelected,
  quoteOriginLink,
  quoteOriginTitle,
  specialAuthor
}) => {
  return (
    <Bx
      bgcolor="accent.light"
      borderRadius="borderRadius"
      p={1}
      border={3}
      borderTop={0}
      borderBottom={0}
      borderRight={0}
      borderColor="accent.main"
      color="text.primary"
      my={1}
    >
      <Bx display={!!remove ? 'flex' : 'inline'}>
        <Link underline="hover" to={getProfileRouteByUserId(message.createdBy)}>
          <Typo fontWeight={500} variant="body2" component="span">
            {specialAuthor || message.createdByResolved?.content?.fullName || 'Ehemaliger Benutzer'}
          </Typo>
        </Link>
        {!!quoteOriginLink && <>
          <Typo fontWeight={500} variant="body2" component="span">
            &nbsp;in&nbsp;
          </Typo>
          <Link underline="hover" to={quoteOriginLink}>
            <Typo fontWeight={500} variant="body2" component="span">
              {quoteOriginTitle}
            </Typo>
          </Link>
        </>}

        {!!remove && (
          <Bx ml="auto" display="flex" justifyContent="flex-end">
            <Btn onClick={() => remove?.()} noXPadding color="primary" size="small" startIcon={<FontAwesomeIcon icon={faTimes} />}>
              Zitat entfernen
            </Btn>
          </Bx>
        )}
      </Bx>
      {!message.deleted &&
        // <Typo>
        // {message.content}</Typo>
        
        <MessageContent
          content={message.content}
          isOwnMessage={false}
          isDeleted={false}
          conferenceInfo={undefined}
          onJoinConference={undefined}
          references={references}
          onReferenceSelected={onReferenceSelected}
          color={undefined}
        />
      }
      {message.deleted && <Typo fontWeight="lighter">Diese Nachricht wurde gelöscht</Typo>}

      {!message.deleted && message.conferenceInfo && onJoinConference && (
        <Bx display="flex" pt={1}>
          <MessageConferenceContent conferenceInfo={message.conferenceInfo} onJoinConference={onJoinConference} />
        </Bx>
      )}
    </Bx>
  );
};
