import { createAction } from '@reduxjs/toolkit';
import { IContainerConfig, INavigationConfig } from '../../../model/configuration';
import { IProfile, IProfileDataVisibility, IProfileMessagingPermissions } from '../../../model/profile';
import { IDictionary } from '../../../helper';
import { IMemoryListItem } from '../../../model/ryve/Thing';
import { IListUser } from '../../../model/search/SearchResult';

export const fetchStartupRequestAction = createAction('FETCH_STARTUP_REQUEST');
export const fetchStartupSuccessAction = createAction<{
  blacklist: string[];
  welcomeversion: string;
  updatetermsrules: boolean;
  anon: boolean;
  appconfig: { [alias: string]: IContainerConfig };
  profile: IProfile;
  navigation: INavigationConfig[];
}>('FETCH_STARTUP_SUCCESS');
export const fetchStartupFailedAction = createAction('FETCH_STARTUP_FAILED');

export const setAppstateMobileAction = createAction<boolean>('SET_APPSTATE_MOBILE');
export const setNavbarOpenAction = createAction<boolean>('SET_NAVBAR_OPEN');

export const setAppStateActiveConfigAction = createAction<IContainerConfig>('SET_APPSTATE_ACTIVE_CONFIG');
export const setUpdateTermsAction = createAction('SET_UPDATE_TERMS');

export const userprofileAllowExternalLinksRequestAction = createAction('USERPROFILE_ALLOW_EXTERNALLINKS_REQUEST');
export const userprofileAllowExternalLinksSuccessAction = createAction('USERPROFILE_ALLOW_EXTERNALLINKS_SUCCESS');
export const userprofileAllowExternalLinksErrorAction = createAction('USERPROFILE_ALLOW_EXTERNALLINKS_ERROR');

export const userprofileChangeDataVisibilityRequestAction = createAction<{ toggleProperty: string }>('USERPROFILE_CHANGE_DATA_VISIBILITY_REQUEST');
export const userprofileChangeDataVisibilitySuccessAction = createAction<{ dataVisibility: IProfileDataVisibility }>('USERPROFILE_CHANGE_DATA_VISIBILITY_SUCCESS');
export const userprofileChangeDataVisibilityErrorAction = createAction('USERPROFILE_CHANGE_DATA_VISIBILITY_ERROR');

export const userprofileChangeMessagingPermissionsRequestAction = createAction<{ toggleProperty: string }>('USERPROFILE_CHANGE_MESSAGING_PERMISSIONS_REQUEST');
export const userprofileChangeMessagingPermissionsSuccessAction = createAction<{ messagingPermissions: IProfileMessagingPermissions }>(
  'USERPROFILE_CHANGE_MESSAGING_PERMISSIONS_SUCCESS'
);
export const userprofileChangeMessagingPermissionsErrorAction = createAction('USERPROFILE_CHANGE_MESSAGING_PERMISSIONS_ERROR');

export const setAvatarAssetsAction = createAction<{ idAssetDict: IDictionary<IListUser> }>('USERPROFILE_SET_AVATAR_ASSETS');

export const userprofileMemorylistRequestAction = createAction<{
  memoryItem: IMemoryListItem;
  type: 'GET' | 'POST' | 'DELETE';
}>('USERPROFILE_MEMORYLIST_REQUEST');
export const userprofileMemorylistSuccessAction = createAction<{ memoryList: Array<IMemoryListItem>; noRefresh?: string }>('USERPROFILE_MEMORYLIST_SUCCESS');
export const userprofileMemorylistErrorAction = createAction('USERPROFILE_MEMORYLIST_ERROR');

export const userprofileAlertReadRequestAction = createAction<{ alertRead?: boolean }>('USERPROFILE_ALERT_READ_REQUEST');
export const userprofileAlertReadSuccessAction = createAction('USERPROFILE_ALERT_READ_SUCCESS');
export const userprofileAlertReadErrorAction = createAction('USERPROFILE_ALERT_READ_ERROR');
